<!-- <mat-tab-group>
  <mat-tab label="ADM Upload">
    <div class="parent-container">
      <mat-card appearance="outlined" class="example-card">
          <div class="highlighsection">
              <h2 class="title">ADM</h2>
          </div>
          <mat-card-content>
              <div class="gis_data_form">
                  <div class="d-flex align-items-center">
                      <div class="ml-10 d-flex align-items-center">
                          <label for="">Assignment <span class="mandatory">*</span> : </label>
                          <mat-select (selectionChange)="selectionChange()" (openedChange)="closeMatSelectEvent($event, 'assignment')" placeholder="Select Assignment" [(ngModel)]="selected_assignment" class="form-select-l">
                              <div class="search_bar"><input [(ngModel)]="searchAssignment" placeholder="Type here to search..." (keydown)="$event.stopPropagation()" /></div>
                              <mat-option class="cp" *ngFor="let data of assignmentList | searchfilter:'name':searchAssignment:assignmentList" [value]="data.assignment_id">
                                  {{ (data.assignment_label && data.assignment_label[api.language]) ? data.assignment_label[api.language] :''}}_{{data?.year}}
                              </mat-option>
                          </mat-select>
                      </div>
                  </div>

                  <div class="gridV">
                      <div class="btnGrid">
                          <div class="upload-btn-wrapper">
                              <div class="wrapper">
                                  <button class="sel">Select file</button>
                                  <p> or Drag & drop file here</p>
                              </div>
                              <input type="file" #myFileInput name="myfile" (change)="handleFileInput($event)" accept=".zip" />
                          </div>
                          <div class="fileName">
                              <p> {{fileName}} </p>
                              <button class="submit" type="submit" [disabled]="loading" mat-raised-button color="primary" (click)="uploadFile()">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                                      <path d="M0 0h24v24H0z" fill="none"></path>
                                      <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path>
                                  </svg>
                                  <span *ngIf="!loading">Upload</span>
                                  <span *ngIf="loading">Uploading...</span>
                              </button>
                          </div>
                      </div>
                  </div>

              </div>
          </mat-card-content>
          <div class="bbar w-100" *ngIf="loading">
              <mat-progress-bar mode="indeterminate" class="btm"></mat-progress-bar>
          </div>

      </mat-card>
      <mat-card appearance="outlined" class="example-card">
          <div class="highlighsection d-flex justify-content-between align-items-center">
              <div class="title">
                  ADM Upload Status
              </div>
              <div class="d-flex align-items-center justify-content-end w-50">
                <div class="Search-cont">
                    <app-admin-search-bar [placeholder]="placeholderText" [maxWidth]="'350px'" (searchEvent)="onSearch($event)"></app-admin-search-bar>
                </div>
              <div class="refresh-cont text-right mr-1" *ngIf="DataADMSource.length > 0">
                  <button mat-icon-button  matTooltip="Refresh list" class="refresh" (click)="getADMStatus()">
                      <mat-icon>refresh</mat-icon>
                  </button>
              </div>
            </div>
          </div>
          <mat-card-content class="tabs">
              <div class="table-estater table" style="position: relative">
                  <ng-container *ngIf="DataADMSource.length > 0">
                      <table class="attr-table">
                          <thead>
                              <tr>
                                 <th>Upload Id
                                    <app-admin-table-sorting
                                    [sortField]="'id'"
                                    [activeField]="sortField"
                                    [sortOrder]="sortOrder"
                                    [activeField]="sortField"
                                    (sortEvent)="onSort($event)">
                                  </app-admin-table-sorting>
                                </th>
                                 <th>File Name
                                    <app-admin-table-sorting
                                    [sortField]="'file_name'"
                                    [activeField]="sortField"
                                    [sortOrder]="sortOrder"
                                    (sortEvent)="onSort($event)">
                                  </app-admin-table-sorting>
                                 </th>
                                 <th>Assignment
                                 </th>
                                 <th>Year
                                    <app-admin-table-sorting
                                    [sortField]="'year'"
                                    [activeField]="sortField"
                                    [sortOrder]="sortOrder"
                                    (sortEvent)="onSort($event)">
                                  </app-admin-table-sorting>
                                 </th>
                                 <th>Add Count</th>
                                 <th>Update Count</th>
                                 <th>Delete Count</th>
                                 <th>Existing Count</th>
                                 <th>Status</th>
                                 <th>Added Date
                                    <app-admin-table-sorting
                                    [sortField]="'added_date'"
                                    [activeField]="sortField"
                                    [sortOrder]="sortOrder"
                                    (sortEvent)="onSort($event)">
                                  </app-admin-table-sorting>
                                 </th>
                                 <th>Added By
                                    <app-admin-table-sorting
                                    [sortField]="'added_by'"
                                    [activeField]="sortField"
                                    [sortOrder]="sortOrder"
                                    (sortEvent)="onSort($event)">
                                  </app-admin-table-sorting>
                                 </th>
                                 <th>Updated By
                                    <app-admin-table-sorting
                                    [sortField]="'updated_by'"
                                    [activeField]="sortField"
                                    [sortOrder]="sortOrder"
                                    (sortEvent)="onSort($event)">
                                  </app-admin-table-sorting>
                                 </th>
                                 <th>Validate End Date</th>
                                 <th>Accept Start</th>
                                 <th>Accept End</th>
                                 <th>Actions</th>
                                 <th>Log</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr *ngFor="let data of DataADMSource; let k = index">
                                  <td>{{data?.id}}</td>
                                  <td>{{data?.file_name}}</td>
                                  <td>{{getAssignmentLabel(data?.assignment_id)}}</td>
                                  <td>{{data?.year}}</td>
                                  <td>{{data?.add_count}}</td>
                                  <td>{{data?.update_count}}</td>
                                  <td>{{data?.delete_count}}</td>
                                  <td>{{data?.existing_count}}</td>
                                  <td>{{status[data?.status]}}</td>
                                  <td>{{getDate(data?.added_date)}}</td>
                                  <td>{{data?.added_by}}</td>
                                  <td>{{data?.updated_by}}</td>
                                  <td>{{getDate(data?.validate_end_date)}}</td>
                                  <td>{{getDate(data?.accept_start_date)}}</td>
                                  <td>{{getDate(data?.accept_end_date)}}</td>
                                  <td><button class="dl-btn accept mr-1" [disabled]="data?.status != 1" (click)="accept_reject(data , 'accept')">Accept</button>
                                      <button class="dl-btn reject mr-1"  [disabled]="data?.status == -3 || data?.status == 2" (click)="accept_reject(data , 'reject')">Reject</button>
                                  </td>
                                  <td>
                                      <button class="dl-btn bordered download"><a href="{{eps_log_path}}{{city_code}}_validate_{{data.id}}.log" target="_blank" class="">{{eps_log_path}}{{city_code}}_validate_{{data.id}}.log Log</a></button>
                                      <button class="dl-btn bordered download"><a href="{{eps_log_path}}{{city_code}}_accept_{{data.id}}.log" target="_blank" class="">Accept Log {{eps_log_path}}{{city_code}}_accept_{{data.id}}.log</a></button>
                                      <button class="dl-btn bordered download"><a href="{{eps_log_path}}{{city_code}}_accept_{{data.id}}_err.log"  target="_blank" class="">Accept Err Log</a></button>
                                      <button class="dl-btn bordered download"><a href="{{eps_log_path}}{{city_code}}_validate_{{data.id}}_err.log"  target="_blank" class="">Validate Log</a></button>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                  </ng-container>

              </div>
              <mat-paginator *ngIf="DataADMSource.length > 0" [length]="total" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageAction($event)">
              </mat-paginator>
              <ng-container *ngIf="DataADMSource.length === 0">
                <app-no-records-found [dataNotFoundMessage]="'No Records Found'" [isNotFoundIconShow]="true"></app-no-records-found>
              </ng-container>
          </mat-card-content>
          <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
      </mat-card>
  </div>
  </mat-tab>
  <mat-tab label="Observer ADM Upload">
  <app-admin-observer-adm-upload></app-admin-observer-adm-upload>
  </mat-tab>
</mat-tab-group> -->

<!-- Adm new UI changes-->
<mat-tab-group>
    <mat-tab label="ADM Upload">
        <div class="header-container d-flex justify-content-between align-items-center padding-top-medium padding-bottom-medium margin-bottom-large m-">
            <div class="header-title-section">
                <div class="title-wrapper d-flex justify-content-start align-items-center">
                    <h5 class="page-title white-space-nowrap">ADM Upload</h5>
                    <div class="ml-10 d-flex align-items-center position-relative">
                        <label for="" class="assignment-label">Assignment <span class="mandatory">*</span></label>
                        <mat-select (selectionChange)="selectionChange()" (openedChange)="closeMatSelectEvent($event, 'assignment')" placeholder="Select Assignment" [(ngModel)]="selected_assignment" class="form-select-l select-ellipsis">
                            <div class="search_bar"><input [(ngModel)]="searchAssignment" placeholder="Type here to search..." (keydown)="$event.stopPropagation()" /></div>
                            <mat-option class="cpZ" *ngFor="let data of assignmentList | searchfilter:'name':searchAssignment:assignmentList" [value]="data.assignment_id">
                                {{ (data.assignment_label && data.assignment_label[api.language]) ? data.assignment_label[api.language] :''}}_{{data?.year}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <app-admin-file-upload [getResourceAccess]="adm_access"  [apiUrl]="apiUrl" [uploadServerType]="'eps'"
                        (change)="handleFileInput($event)" (getAfterUpload)="AfterUploadFile()" accept=".zip">
                    </app-admin-file-upload>
                </div>
            </div>
            <div class="header-actions d-flex">
                <app-admin-search-bar *ngIf="selected_assignment" class="srch-bar" [placeholder]="placeholderText" [maxWidth]="'400px'"
                    (searchEvent)="onSearch($event)"></app-admin-search-bar>
                <!-- status dropdown -->
                <app-admin-selection-dropdown *ngIf="selected_assignment"
                    class="status-drop-down"
                    [label]="'Status'"
                    [options]="statusOptions"
                    (selectionChangecustom)="onStatusChange($event)"
                >
                </app-admin-selection-dropdown>
                <div class="refresh-cont text-right mr-1" *ngIf="DataADMSource.length > 0">
                    <button class="refresh-button" matButton matTooltip="Refresh list" (click)="getADMStatus()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 22 22">
                            <g id="Group_83" data-name="Group 83" transform="translate(-490.906 -11.638)">
                                <circle id="Ellipse_2" data-name="Ellipse 2" cx="11" cy="11" r="11"
                                    transform="translate(490.906 11.638)" fill="#6449db" />
                                <path id="Path_7" data-name="Path 7"
                                    d="M17.4,15.516c.242-.032.521,0,.549.312l.157,1.761a.359.359,0,0,1-.714.062l-.09-1.006A6.66,6.66,0,0,1,5.708,11.127a.36.36,0,0,1,.709.124A5.941,5.941,0,0,0,16.643,16.3l-.782.07a.359.359,0,0,1-.062-.714l1.6-.143ZM8.813,8.185l-.925.083a5.941,5.941,0,0,1,10.226,5.053.36.36,0,0,0,.709.124A6.66,6.66,0,0,0,7.362,7.78l-.078-.87a.359.359,0,0,0-.714.062l.156,1.746a.358.358,0,0,0,.407.338L8.875,8.9A.359.359,0,0,0,8.813,8.185Z"
                                    transform="translate(490.136 10.345)" fill="#fff" stroke="#fff" stroke-width="0.8"
                                    fill-rule="evenodd" />
                            </g>
                        </svg>
                    </button>
                </div>
                <ng-container>
                    <app-admin-table-setting
                    class="customize-icon"
                    [allColumns]="allColumns" 
                    [defaultColumns]="defaultColumn" 
                    (columnsChanged)="updateColumns($event)">
                </app-admin-table-setting>
                </ng-container>
            </div>
        </div>
        
        <mat-card-content class="tabs">
            <div class="" style="position: relative">
                <ng-container>
                    <app-admin-data-table
                        [data]="DataADMSource"
                        [columns]="tableColumn"
                        [totalCount]="totalRecords"
                        [statusMap]="status"
                        [buttons]="buttonConfig"
                        (pageChangeAction)="pageChangeAction($event)"
                        [isIcon]="true"
                        [tableClass]="'brand-table-wrapper'"
                        (sortChange)="onSort($event)"
                        (resetSortingEvent)="resetSorting()"
                        [isSorting]="true"
                        [showAllLog]="true"
                        >
                    </app-admin-data-table>
                </ng-container>
            </div>
        </mat-card-content>
        <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
    </mat-tab>
    <mat-tab label="Observer ADM Upload">
        <app-admin-observer-adm-upload></app-admin-observer-adm-upload>
    </mat-tab>
</mat-tab-group>


import {
  Component,
  OnInit,
  HostListener,
  AfterViewInit,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { AdminMenusService } from "../admin-sidebar/admin-menus.service";
import { AdminBreadcrumbService } from "../admin-breadcrumb/services/admin-breadcrumb.service";
import { Options } from "sortablejs";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent, MatPaginator } from "@angular/material/paginator";
import * as $ from "jquery";
import { ApiserviceService } from "../../apiservice.service";
import { NotificationService } from "../services/notification.service";
import { CommonfunctionService } from "../../services/commonfunction.service";
import { Router } from "@angular/router";
import { ShareService } from "../services/share.service";
import { debounceTime } from "rxjs";
import { CreateFieldDialog } from "./components/create-update-field/admin-createfields.component";
import { ShuffleConfirmationDialog } from "./components/Shuffle-Confirmation-Dialog/shuffle-confirmation-dialog.component";
import { FieldShuffleDialog } from "./components/field-shuffle-dialog/field-suffle-dialog.component";
import { ChangeLanguageDialog } from "./components/language-change/change-language-dialog.component";
import { ChangeDescriptionLanguageDialog } from "./components/changed-scription-language/changede-scription-language.component";
import { GroupFieldsList } from "./components/admin-group-list/admin-grouplist.component";
import { FieldSubmasterContextMenu } from "./components/field-submaster-contextmenu/field-submaster-contextmenu.component";
import { FieldMasterContextMenu } from "./components/field-master-contextmenu/field-master-contextmenu.component";

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_module_type_entity_attributes_patch</li>
 * <li>umsapi_user_resource_post</li>
 * <li>estapi_entities_get</li>
 * <li>estapi_propertytypes_get</li>
 * <li>estapi_module_type_entity_entities_attributes_get</li>
 * <li>estapi_module_type_entity_attributegroups_get</li>
 * <li>estapi_module_type_entity_entities_attributes_attribute_id_patch</li>
 * <li>estapi_moduletypes_get</li>
 * <li>estapi_assignments_get</li>
 * </ol>
 *
 */

@Component({
  selector: "app-admin-field",
  templateUrl: "./admin-field.component.html",
  styleUrls: ["./admin-field.component.scss"],
  entryComponents: [
    GroupFieldsList,
    FieldSubmasterContextMenu,
    FieldMasterContextMenu,
    ChangeLanguageDialog,
  ],
})
export class AdminFieldComponent implements OnInit, AfterViewInit {
  @ViewChild(GroupFieldsList, { static: true }) groupclass: GroupFieldsList;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  total: number;
  pageSizeOptions: number[] = [5, 10, 15, 25, 50, 100];
  defaultPageSize: number = 10;
  pageEvent: PageEvent;
  pageIndex = 0;
  searchAssignment = "";
  searchType = "";
  pageNo: FormControl;
  limit: number = 10;
  offset: number = 0;
  quick_actions: any;
  undefined_attributes: any;
  total_records: any;
  isundefinedActive: boolean = true;
  isQuickActionActive: boolean = false;
  // pageAction(e) {
  //   this.defaultPageSize = e.pageSize;
  //   this.pageIndex = e.pageIndex;
  //   this.pageNo.setValue(e.pageIndex + 1);
  //   this.limit = e.pageSize;
  //   this.offset = this.pageIndex * e.pageSize;

  //   if (this.selected_group === "quick") {
  //     this.total_records = this.quick_actions?.attribute_count || 0;
  //     // this.getQuickActionAttribute();
  //     this.getFields("sort_order", this.limit, this.offset, "", true);
  //     this.getTotalcount("sort_order", null, null, "", true);
  //   } else if (this.selected_group === "undefined") {
  //     this.total_records = this.undefined_attributes?.attribute_count || 0;
  //     // this.getAttributUndefine();
  //     this.getFields("sort_order", this.limit, this.offset, "undefined", false);
  //     this.getTotalcount("sort_order", null, null, "undefined", false);
  //   } else {
  //     let selectedGroup = this.group_list.find(
  //       (group) => group.group_name === this.selected_group
  //     );
  //     this.total_records = selectedGroup?.attribute_count || 0;
  //     this.getFields("sort_order", this.limit, this.offset, "", false);
  //     this.getTotalcount("sort_order", null, null, "", false);
  //   }
  // }
  displayedColumns = [
    "checkboxes",
    "sno",
    "name",
    "language",
    "description_language",
    "status",
    "show_hide",
    "mandatory",
    "listable",
    "editable",
  ];
  tableoptions: Options = {
    draggable: ".draggableset",
    onUpdate: (event: any) => this.sortFields(),
  };
  attributegroups_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };
  attributes_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };
  assignment_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };
  module_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };
  property_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };
  entity_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };
  searchField: FormControl;
  mobilemasterpopup = false;
  mobilemasterpopupslideout = false;
  selected_property_type: number;
  selected_entity_type: string;
  search_input: string;
  selected_assignment: number;
  selected_module: string;
  selected_group: any;
  loader: boolean;
  undefined_length: number;
  is_property_dependent: boolean;
  is_assignment_dependent: boolean;
  selected_attributes_id: Array<number> = [];
  module_list: Array<any>;
  group_list = [];
  total_groups: number = 0;
  field_list: Array<any> = [];
  group_custom_list: any;
  assignment_list: Array<any>;
  groupdata: {} = {};
  languageeditdata: {} = {};
  showsearchbar: boolean = false;
  showmobilesearchbar: boolean = false;
  ready_load: boolean = false;
  field_group: any;
  dependent_list: any;
  confirm: boolean;
  // height: number;
  // inHeight: number;
  callFrom: string = "adminfield";
  showRemove: boolean = false;
  currentFetchingGroup: any;
  isQuickActionAssigningInGroup: boolean = false;

  constructor(
    public dialog: MatDialog,
    private menus: AdminMenusService,
    private _crumbs: AdminBreadcrumbService,
    private _el: ElementRef,
    public notify: NotificationService,
    private fnc: CommonfunctionService,
    public api: ApiserviceService,
    private route: Router,
    private share: ShareService,
    private cdr: ChangeDetectorRef
  ) {
    this.pageNo = new FormControl();
    this.share.fieldObj = this;
    this.menus.setActive("field_settings");
    this._crumbs.clear();
    this._crumbs.addcrumb = { title: "Attribute Settings" };
    this._crumbs.addcrumb = { title: "Group" };
    this._crumbs.mobiletitle = "Attribute Settings";
    this._crumbs.count = 1;
    this.onResize();
    this.getAllowResourcesApi();
    this.getAssignment();
  }
  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    // this.height = (window.innerHeight - 220);
    // this.inHeight = (window.innerHeight - 288);
  }
  openConfirmationDialog() {
    let confimDialog = this.dialog.open(ShuffleConfirmationDialog, {
      width: "300px",
      panelClass: "create-master-panel",
      data: { parent: this },
    });
    confimDialog.afterClosed().subscribe((result) => {});
  }

  getAttributUndefine() {
    console.log("📌 Calling getAttributUndefine()");
    this.isundefinedActive = true;
    this.isQuickActionActive = false;
    // ✅ Explicitly update selected group before fetching data
    this.selected_group = this.group_list.find(
      (group) => group.group_name === "undefined"
    ) || {
      id: -1,
      group_name: "Unassigned Attributes",
    };
    this.showRemove = false; // Hide remove button if applicable
    this.selected_attributes_id = []; // Reset selected attributes
    this.resetpagination(); // ✅ Reset pagination before fetching data

    console.log("✅ Updated selected_group to:", this.selected_group);

    // ✅ Fetch attributes data for undefined group
    this.fetchAttributesData("undefined", false, this.limit, 0, "");
  }

  getQuickActionAttribute() {
    this.isQuickActionActive = true;
    this.selected_group = "quick";
    this.fetchAttributesData("quick", true, this.limit, 0);
  }
  getTotalcount(
    sort: string,
    limit: number,
    offset: number,
    search: string = "",
    isQuickEdit: boolean = false
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      this.selected_attributes_id = []; // Reset selected attributes
      if (isQuickEdit) this.share.quick_edit_count.next(0);

      let searchString = search;
      if (!this.attributes_access.GET) {
        this.field_list = [];
        resolve(0);
        return;
      }

      // ✅ Ensure selected group is properly handled
      if (!this.selected_group || this.selected_group === "undefined") {
        this.selected_group = "-1";
      }

      if (this.selected_group === "-1") {
        searchString = "&group_id=-1"; // ✅ Handle undefined groups correctly
        this.undefined_length = 0;
      } else if (this.selected_group !== "quick") {
        searchString += "&group_id=" + this.selected_group?.id;
      }

      if (isQuickEdit) {
        sort = "quick_edit_sort";
        searchString += "&quick_edit=true";
      }

      if (this.is_property_dependent) {
        searchString += "&property_type_ids=" + this.selected_property_type;
      }

      let url = `${this.selected_module}/${this.selected_entity_type}/attributes?sort_by=${sort}&limit=10000&offset=0&assignment_id=${this.selected_assignment}${searchString}`;

      this.api.getEmsData(url).subscribe({
        next: (res: any) => {
          if (Array.isArray(res)) {
            this.total = res.length;
            this.total_records = res.length; // ✅ Ensure UI updates
            this.cdr.detectChanges(); // ✅ Force UI refresh

            if (isQuickEdit) {
              this.share.quick_edit_count.next(res.length);
              console.log(res.length, "quickAction");
              if (this.isQuickActionAssigningInGroup) {
                this.quick_actions.attribute_count = res.length;
              }
            } else if (this.selected_group === "-1") {
              this.undefined_length = res.length;
              if (!searchString.trim() || searchString === "&group_id=-1") {
              }
            }
          } else {
            this.total = 0; // ✅ Reset count if response is not an array
            this.total_records = 0;
          }
          resolve(this.total);
        },
        error: (err) => {
          this.total = 0;
          this.total_records = 0;
          reject(err);
        },
      });
    });
  }
  isQuickActionAssigning() {
    this.isQuickActionAssigningInGroup = true;
  }
  removeFromQuickAction() {
    let url =
      this.selected_module + "/" + this.selected_entity_type + "/attributes";
    let val = {};
    this.field_group = [];
    this.selected_attributes_id.forEach((v) => {
      val = {
        updated_by: this.api.user_id,
        attribute_id: v,
        quick_edit: false,
        move_linked_attributes: this.confirm,
        assignment_id: this.selected_assignment,
      };
      this.field_group.push(val);
    });
    this.api.patchEmsData(url, this.field_group).subscribe({
      next: (data: any) => {
        this.selected_attributes_id = [];
        this.notify.notify(
          "Attributes successfully removed from Quick Edit!",
          "success"
        );
        // this.resetpagination();
        this.getQuickActionAttribute();
      },
      error: (err) => {
        // this.selected_attributes_id = [];
        //this.notify.notify(this.fnc.getErrorMessage(err), 'error');
        if (err.status == 500 && !this.confirm) {
          let end,
            dependent_string = "";
          let msg = this.fnc.getErrorMessage(err);
          let list = msg.split("{");
          list.forEach((element, k) => {
            if (k != 0) {
              end = element.indexOf("}");
              if (end != -1) {
                dependent_string += element.substring(0, end) + "|";
              }
            }
          });
          this.dependent_list = dependent_string;
          this.openConfirmationDialog();
          this.confirm = false;
        }
      },
      complete: () => {
        this.confirm = false;
        this.selected_attributes_id = [];
      },
    });
  }
  // getting allow resources
  getAllowResourcesApi() {
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`;
    this.api.getUmsData(body).subscribe({
      next: (res: any) => {
        this.api.allowResources = res.data;
        this.getAllowResource();
        this.getModule();
      },
      error: (err) => {},
    });
  }

  // getting list of entity type
  getEntityType() {
    this.api.getEmsData("entities").subscribe((data: any) => {
      this.api.entity_type_list = data;
      this.getPropertyType();
    });
  }

  ///selection on Change from top reset count of quick edit and undefiled attribute
  selectionChange(val) {
    this.undefined_length = 0;
    this.share.quick_edit_count.next(0);
    this.resetpagination();
    // this.resetPaginat();
    this.searchAttribute(val);
  }

  onEntityTypeChange() {
    this.undefined_length = 0;
    this.share.quick_edit_count.next(0);

    let entity_info = this.fnc.getArrayValue(
      "entity_name",
      this.selected_entity_type,
      this.api.entity_type_list
    );

    this.is_property_dependent =
      entity_info?.is_property_type_dependent || false;

    // Ensure selected group is set to "-1" instead of undefined
    this.selected_group = "-1";

    this.getGroups("sort_order", this.limit, 0, "-1")
      .then(() => {
        this.getAttributUndefine();
        console.log("Group changed to -1 due to entity type change.");
      })
      .catch((error) => {
        console.error("Error fetching groups:", error);
      });
  }

  // getting list of property type
  getPropertyType(sort_by = "property_type_name", limit = 10000, offset = 0) {
    let url =
      "propertytypes?sort_by=" +
      sort_by +
      "&limit=" +
      limit +
      "&offset=" +
      offset +
      "&status=1";
    this.api.getEmsData(url).subscribe((data: any) => {
      this.api.property_type_list = data;
      this.selected_entity_type = this.api.entity_type_list[0].entity_name;
      this.selected_property_type =
        this.api.property_type_list[0].property_type_id;
      if (this.assignment_list)
        this.selected_assignment = this.assignment_list[0].assignment_id;
      this.selected_module = this.module_list[0].module_type_name;
      this.searchAttribute(false);
    });
  }
  searchAttribute(val, quickEdit = false) {
    if (!quickEdit) {
      // this.showRemove = false;
      this.share.gpObj.activeToggle = false;
    } else {
      this.showRemove = true;
    }
    let seachString = "",
      property_search = "",
      text = "";
    let entity_info = this.fnc.getArrayValue(
      "entity_name",
      this.selected_entity_type,
      this.api.entity_type_list
    );
    let module_info = this.fnc.getArrayValue(
      "module_type_name",
      this.selected_module,
      this.module_list
    );

    this.is_property_dependent = entity_info.is_property_type_dependent;
    this.is_assignment_dependent = module_info.is_assignment_dependent;
    this.resetpagination();
    if (this.search_input) {
      text = "&attribute_name=" + this.search_input;
    }
    seachString = property_search + text;
    if (!val) {
      this.resetpagination();
      this.getGroups("sort_order", 100, 0, seachString);
    } else {
      // this.getFields("sort_order", this.limit, 0, seachString, quickEdit);
      console.log(
        "searchAttribute(val, quickEdit = false)",
        "getFileds is called"
      );
      this.getTotalcount("sort_order", null, null, seachString, quickEdit);
    }
    this.resetpagination();
  }

  getFields(
    sort: string,
    limit: number,
    offset: number,
    search: string = "",
    isQuickEdit: boolean = false
  ) {
    this.selected_attributes_id = [];
    if (isQuickEdit) this.share.quick_edit_count.next(0);
    if (!this.attributes_access.GET) {
      this.field_list = [];
      return;
    }

    // If selected group is undefined/null, use group_id=-1
    if (!this.selected_group || this.selected_group === "undefined") {
      this.selected_group = "-1";
    }

    if (this.selected_group === "-1") {
      search += "&group_id=-1"; // ✅ Ensure undefined groups are handled
      this.undefined_length = 0;
    } else if (this.selected_group && this.selected_group !== "quick") {
      search += "&group_id=" + this.selected_group?.id;
      console.log("this.selected_group?.id", this.selected_group?.id);
    }

    if (isQuickEdit) {
      sort = "quick_edit_sort";
      search += "&quick_edit=true";
    }

    if (this.is_property_dependent) {
      search += "&property_type_ids=" + this.selected_property_type;
    }

    if (limit > 500) {
      console.warn("⚠️ Unexpectedly large limit detected:", limit);
      return;
    }

    this.loader = true;
    let url = `${this.selected_module}/${this.selected_entity_type}/attributes?sort_by=${sort}&limit=${limit}&asc=true&offset=${offset}&assignment_id=${this.selected_assignment}${search}`;

    this.api.getEmsData(url).subscribe({
      next: (data: any) => {
        if (isQuickEdit) {
          this.share.quick_edit_count.next(data.length);
        }
        this.field_list = [];
        this.loader = false;

        if (data.length) {
          let ctr = 1 + this.offset;
          data.forEach((element) => {
            let val = {
              sno: ctr,
              name: element.attribute_name,
              label:
                element.attribute_label?.[this.api.language] ??
                element.attribute_name,
              description: element.description?.[this.api.language] ?? "",
              id: element.attribute_id,
              status: element.status,
              property_type_id: element.property_type_id,
              labels: element.attribute_label,
              descriptions: element.description,
              is_hidden: element.is_hidden,
              is_mandatory: element.is_mandatory,
              is_listable: element.is_listable,
              is_editable: element.is_editable,
              group_id: element.group_id,
              mutually_exclusive: element.mutually_exclusive_attributes,
              dependent: element.dependent_attributes,
              parent_id: element.parent_attribute_id,
            };

            ctr++;
            this.field_list.push(val);
          });
        }
      },
      error: () => {
        this.field_list = [];
        this.loader = false;
      },
    });
  }

  // getGroups(sort: string, limit: number, offset: number, search: string = "") {
  //   if (this.is_property_dependent) {
  //     search += "&property_type_ids=" + this.selected_property_type;
  //   }
  //   let url =
  //     this.selected_module +
  //     "/" +
  //     this.selected_entity_type +
  //     "/attributegroups?sort_by=" +
  //     sort +
  //     "&limit=" +
  //     limit +
  //     "&offset=" +
  //     offset +
  //     "&assignment_id=" +
  //     this.selected_assignment +
  //     search;
  //   this.group_custom_list = [];
  //   this.group_list = [];
  //   this.field_list = [];
  //   if (!this.attributegroups_access.GET) {
  //     return;
  //   }
  //   // this.getTotalcount("sort_order", null, null, "undefined");
  //   // console.log("getGroups(sort: string, limit: number,", "getFileds is called");

  //   this.total_groups = 0;
  //   this.api.getEmsData(url).subscribe((data: any) => {
  //     this.group_list = data?.attribute_groups;
  //     this.total_groups = this.group_list.length;
  //     this.getFields("sort_order", this.limit, this.offset, "undefined");
  //     data?.attribute_groups?.forEach((element) => {
  //       if (!element.parent_group_id) {
  //         let list = {
  //           group_name:
  //             element.group_label[this.api.language] == undefined
  //               ? element.group_name
  //               : element.group_label[this.api.language],
  //           childrens: [],
  //           id: element.group_id,
  //           name: element.group_name,
  //           property_id: element.property_type_id,
  //           labels: element.group_label,
  //           description: element.description,
  //           status: element.status,
  //           parent_id: element.parent_group_id,
  //           attributes_count: element.attribute_count,
  //           sub_group_count: 0,
  //         };
  //         let group_ctr = 0;
  //         let attribute_group_count = 0;
  //         this.group_list.forEach((sub) => {
  //           if (sub.parent_group_id == element.group_id) {
  //             let sub_list = {
  //               group_name:
  //                 sub.group_label[this.api.language] == undefined
  //                   ? sub.group_name
  //                   : sub.group_label[this.api.language],
  //               id: sub.group_id,
  //               name: sub.group_name,
  //               property_id: sub.property_type_id,
  //               labels: sub.group_label,
  //               description: sub.description,
  //               status: sub.status,
  //               parent_id: sub.parent_group_id,
  //               attributes_count: sub.attribute_count,
  //             };
  //             list.childrens.push(sub_list);
  //             attribute_group_count += sub.attribute_count;
  //             list.sub_group_count = ++group_ctr; // counting sub group
  //           }
  //         });
  //         list.attributes_count += attribute_group_count;
  //         this.group_custom_list.push(list);

  //       }
  //     });
  //   });
  // }

  getGroups(
    sort: string,
    limit: number,
    offset: number,
    search: string = ""
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      let queryParams = new URLSearchParams();
      queryParams.set("sort_by", sort);
      queryParams.set("limit", limit.toString());
      queryParams.set("offset", offset.toString());
      queryParams.set("assignment_id", this.selected_assignment.toString());
      queryParams.set("api_version", "V2");

      if (search) queryParams.set("search", search);
      if (this.is_property_dependent) {
        queryParams.set(
          "property_type_ids",
          this.selected_property_type.toString()
        );
      }

      let url = `${this.selected_module}/${
        this.selected_entity_type
      }/attributegroups?${queryParams.toString()}`;
      if (!this.attributegroups_access.GET) return reject("Access Denied");

      this.group_custom_list = [];
      this.group_list = [];
      this.field_list = [];

      this.api.getEmsData(url).subscribe(
        (data: any) => {
          // const moduleData = data[0]?.est_get_attribute_groups_for_module;
          this.group_list = data?.attribute_groups || [];
          this.quick_actions = data?.quick_actions;
          this.undefined_attributes = data?.undefined_attributes;
          this.total_groups = this.group_list.length;

          console.log(
            this.undefined_attributes?.attribute_count,
            "undefined_attributes",
            this.quick_actions?.attribute_count,
            "quick_actions"
          );
          console.log("API Response:", data);

          this.group_list.forEach((element) => {
            if (!element.parent_group_id) {
              let list = {
                group_name:
                  element.group_label?.[this.api.language] ??
                  element.group_name,
                childrens: [],
                id: element.group_id,
                name: element.group_name,
                property_id: element.property_type_id,
                labels: element.group_label,
                description: element.description,
                status: element.status,
                parent_id: element.parent_group_id,
                attributes_count: element.attribute_count,
                sub_group_count: 0,
              };

              let group_ctr = 0;
              let attribute_group_count = 0;

              this.group_list.forEach((sub) => {
                if (sub.parent_group_id === element.group_id) {
                  let sub_list = {
                    group_name:
                      sub.group_label?.[this.api.language] ?? sub.group_name,
                    id: sub.group_id,
                    name: sub.group_name,
                    property_id: sub.property_type_id,
                    labels: sub.group_label,
                    description: sub.description,
                    status: sub.status,
                    parent_id: sub.parent_group_id,
                    attributes_count: sub.attribute_count,
                  };
                  list.childrens.push(sub_list);
                  attribute_group_count += sub.attribute_count;
                  list.sub_group_count = ++group_ctr;
                }
              });

              list.attributes_count += attribute_group_count;
              this.group_custom_list.push(list);
            }
          });
          this.resetpagination();
          if (this.selected_group === "quick") {
            this.total_records = this.quick_actions?.attribute_count || 0;
          } else if (this.selected_group === "undefined") {
            this.total_records =
              this.undefined_attributes?.attribute_count || 0;
          } else {
            let selectedGroup = this.group_list.find(
              (group) => group.group_name === this.selected_group
            );
            this.total_records = selectedGroup?.attribute_count || 0;
            console.log(selectedGroup, "selectedGroup");
          }

          this.pageAction({
            pageSize: this.limit,
            pageIndex: this.offset / this.limit,
          });
          this.resetpagination();
          resolve();
        },
        (error) => {
          console.error("Error fetching attribute groups:", error);
          reject(error);
        }
      );
    });
  }

  updateFields(field: string, element: any, event) {
    let id = element.id;
    const checkbox = event.source as HTMLInputElement;
    const newStatus = checkbox.checked ? 1 : 0;
    let value = 0;
    let url =
      this.selected_module +
      "/" +
      this.selected_entity_type +
      "/attributes/" +
      id;
    let record = {
      updated_by: this.api.user_id,
      assignment_id: this.selected_assignment,
    };
    if (element.parent_id != null) {
      record["parent_attribute_id"] = element.parent_id;
    }
    if (event.checked) value = 1;
    record[field] = value;
    this.api.patchEmsData(url, record).subscribe({
      next: (data: any) => {
        this.getFields('sort_order', this.limit, this.offset, '');
        if (data.status == 201) {
          this.notify.notify("Row updated", "success");
          // this.getFields("sort_order", this.limit, this.offset);
          console.log(
            "updateFields(field: string, element: any, event)",
            "getFileds is called"
          );
        } else if (data.status == 400) {
          this.notify.notify(data.message, "warn");
          checkbox.checked = !checkbox.checked;
        }
      },
      error: (err) => {
        this.notify.notify(this.fnc.getErrorMessage(err), "error");
      },
    });
  }

  getAllowResource() {
    // attributes groups
    this.attributegroups_access =
      this.fnc.checkResourceAccess("attributegroups");
    this.attributes_access = this.fnc.checkResourceAccess("attributes");
    this.assignment_access = this.fnc.checkResourceAccess("assignments");
    this.module_access = this.fnc.checkResourceAccess("moduletypes");
    this.property_access = this.fnc.checkResourceAccess("propertytypes");
    this.entity_access = this.fnc.checkResourceAccess("entities");
    if (!this.module_access.GET) {
      this.module_list = [];
    }
    if (!this.assignment_access.GET) {
      this.assignment_list = [];
    }
    if (!this.property_access.GET) {
      this.api.property_type_list = [];
    }
    if (!this.entity_access.GET) {
      this.api.entity_type_list = [];
    }
  }

  getModule() {
    this.api.getEmsData("moduletypes").subscribe({
      next: (data: any) => {
        this.module_list = data;
        this.getEntityType();
      },
      error: (err) => {},
    });
  }

  getAssignment() {
    this.api
      .getEmsData("assignments?sort_by=assignment_name&status=1")
      .subscribe({
        next: (data: any) => {
          //change sort order
          data = this.fnc.assignmentSortOrder(data);
          this.assignment_list = [];
          data.forEach((v) => {
            if (v.assignment_label[this.api.language]) {
              v["label"] = v.assignment_label[this.api.language];
            } else {
              v["label"] = v.assignment_name;
            }
            this.assignment_list.push(v);
          });
        },
        error: (err) => {},
      });
    this.resetpagination();
  }

  setAttributeId(e) {
    if (e.checked) {
      this.selected_attributes_id.push(e.source.value);
    } else {
      this.selected_attributes_id.splice(
        this.selected_attributes_id.indexOf(e.source.value),
        1
      );
    }
  }
  // selectGroup(item) {
  //   this.undefined_length = this.undefined_total_records;
  //   this.selected_group = item;
  //   this.limit = 10;
  //   this.offset = 0;
  //   this.showRemove = false;
  //   this.selected_attributes_id = []; //pointer
  //   this.selected_group = item;
  //   this.resetPaginat()
  //   this.resetpagination();
  //   // this.getTotalcount("sort_order", null, null, "", false);
  //   // this.getFields("sort_order", this.limit, this.offset, "", false);
  //   // console.log("selectGroup", "getFileds is called");

  // }
  selectGroup(item: any) {
    this.isQuickActionActive = false;
    console.log("selectGroup called with item:", item);
    console.trace();
    this.isundefinedActive = false;
    if (this.selected_group?.id === item?.id) {
      console.log("🔄 Re-selecting same group. Forcing data fetch...");
      this.fetchAttributesData(
        item.group_name,
        false,
        this.limit,
        this.offset,
        ""
      );
      return;
    }

    // ✅ Update selected_group immediately
    this.selected_group = item;

    this.showRemove = false;
    this.selected_attributes_id = [];
    this.resetpagination();

    let groupName = item.group_name;
    this.currentFetchingGroup = groupName; // Track current fetching group

    if (groupName === "quick") {
      this.total_records = this.quick_actions?.attribute_count || 0;
      this.fetchAttributesData("quick", true, this.limit, this.offset, "");
    } else if (groupName === "undefined") {
      this.total_records = this.undefined_attributes?.attribute_count || 0;
      this.fetchAttributesData(
        "undefined",
        false,
        this.limit,
        this.offset,
        "-1"
      );
    } else {
      let selectedGroup = this.group_list.find(
        (group) => group.group_name === groupName
      );
      this.total_records = selectedGroup?.attribute_count || 0;

      if (!selectedGroup) {
        console.warn("⚠️ Group not found in group_list!", groupName);
      }

      this.fetchAttributesData(groupName, false, this.limit, this.offset, "");
    }

    console.log("✅ Updated selected_group:", this.selected_group);
  }

  sortFields() {
    let fields = [];
    let val = {};

    if (!this.attributes_access.PATCH) {
      this.notify.notify("You are not authorize to update Attribute", "warn");
      return;
    }
    if (this.selected_group == "quick") {
      this.field_list.forEach((v, k) => {
        v.sno = k + 1; // serial number
        val = {
          attribute_id: v.id,
          quick_edit_sort: k,
          updated_by: this.api.user_id,
          assignment_id: this.selected_assignment,
        };
        fields.push(val);
      });
    } else {
      this.field_list.forEach((v, k) => {
        v.sno = k + 1; // serial number
        val = {
          attribute_id: v.id,
          sort_order: k,
          updated_by: this.api.user_id,
          assignment_id: this.selected_assignment,
        };
        fields.push(val);
      });
    }
    let url =
      this.selected_module + "/" + this.selected_entity_type + "/attributes";
    this.api.patchEmsData(url, fields).subscribe({
      next: (data: any) => {
        //this.notify.notify('Groups order updated','Dismiss');
      },
      error: (err) =>
        this.notify.notify(this.fnc.getErrorMessage(err), "error"),
    });
  }

  selectField(val) {
    let grouplist: any;
    let selected_id: number;

    if (val.group_id) {
      let group = this.fnc.getArrayValue(
        "group_id",
        val.group_id,
        this.group_list
      );

      if (group.parent_group_id) {
        selected_id = group.parent_group_id;
        grouplist = this.fnc.getArrayValue(
          "id",
          group.parent_group_id,
          this.group_custom_list
        )?.childrens;
      } else {
        selected_id = group.group_id;
        grouplist = this.group_custom_list;
      }

      let current_group = this.fnc.getArrayValue("id", val.group_id, grouplist);
      this.selected_group = current_group;

      // Ensure correct selection handling
      this.groupclass.select(selected_id);
      this.groupclass.sub_selected = current_group?.id || null;
    }
  }

  loadlistevent(event) {
    this.groupdata = event.item;
    this.openDialog(event.form, { val: event.item });
  }

  triggerswipe(data) {
    this.swiped();
  }

  swiped() {
    this.mobilemasterpopupslideout = true;
    setTimeout(
      function () {
        this.mobilemasterpopup = false;
      }.bind(this),
      1000
    );
  }

  saveForm(form) {
    this.notify.notify("Form Saved", "Dismiss");
    //Send to the API
    // this.groups.push(form.value);
  }
  //drag start....
  draggable = {
    data: "myDragData",
    effectAllowed: "all",
    disable: false,
    handle: false,
  };
  onDragStart(e) {}
  onDragCanceled(e) {}
  //drag end....
  // ngOnInit() {
  //   this.searchField = new FormControl();
  //   this.searchField.valueChanges.pipe(debounceTime(1000)).subscribe({
  //     next: (term) => {
  //       this.search_input = term;
  //       this.searchAttribute(true);
  //     },
  //   });
  // }

  ngOnInit() {
    this.searchField = new FormControl();
    this.getAttributUndefine();
    this.searchField.valueChanges.pipe(debounceTime(1000)).subscribe({
      next: (term) => {
        console.log("Search term:", term); // Debug log
        let searchQuery = term ? `&attribute_name=${term}` : "";

        // 🔹 Call fetchAttributesData directly
        this.fetchAttributesData(
          this.selected_group,
          false,
          this.limit,
          0,
          searchQuery
        );
      },
    });
  }

  pageAction(e) {
    console.log("📄 Page action triggered:", e);

    this.defaultPageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.pageNo.setValue(e.pageIndex + 1);
    this.limit = e.pageSize;
    this.offset = this.pageIndex * e.pageSize;

    console.log(
      "Updated pagination -> limit:",
      this.limit,
      "offset:",
      this.offset,
      "pageIndex:",
      this.pageIndex
    );

    // Call fetchData with updated pagination values
    this.fetchAttributesData(
      this.selected_group,
      false,
      this.limit,
      this.offset
    );
  }

  fetchAttributesData(
    groupType: string,
    quickEdit = false,
    limit = this.limit,
    offset = 0,
    search: string = ""
  ) {
    let searchString = search.trim(); // ✅ Trim search to remove extra spaces

    // If selected group is "undefined" or null, use group_id = "-1"
    if (!groupType || groupType === "undefined") {
      groupType = "-1"; // ✅ Convert undefined group to "-1"
    }

    // 🔹 Always reset offset to 0 when a search term is provided
    if (searchString.length > 0) {
      offset = 0;
      this.resetpagination();
    }

    if (groupType === "quick") {
      this.total_records = this.quick_actions?.attribute_count || 0;
      this.getFields("sort_order", limit, offset, searchString, true);
      this.getTotalcount("sort_order", null, null, searchString, true);
    } else if (groupType === "-1") {
      this.total_records = this.undefined_attributes?.attribute_count || 0;
      this.getFields("sort_order", limit, offset, searchString, false);
      this.getTotalcount("sort_order", null, null, searchString, false);
    } else {
      let selectedGroup = this.group_list.find(
        (group) => group.group_name === groupType
      );
      this.total_records = selectedGroup?.attribute_count || 0;
      this.getFields("sort_order", limit, offset, searchString, false);
      this.getTotalcount("sort_order", null, null, searchString, false);
    }

    console.log(
      `🔍 Data fetched for group: ${groupType}, Limit: ${limit}, Offset: ${offset}, Search: ${search}`,
      this.total_records
    );
  }

  resetpagination() {
    console.log("🔄 Resetting pagination...");

    this.defaultPageSize = 10;
    this.limit = 10; // ✅ Ensure limit is reset
    this.offset = 0;
    this.pageIndex = 0;
    this.pageNo?.setValue(1);

    console.log("✅ Pagination Reset ->", {
      limit: this.limit,
      offset: this.offset,
      pageIndex: this.pageIndex,
    });

    if (this.paginator) {
      console.log("📌 Resetting paginator...");
      this.paginator.pageSize = 10; // ✅ Sync paginator with reset values
      this.paginator.pageIndex = 0;
      this.paginator.firstPage();
    } else {
      console.warn("⚠️ Paginator is not initialized yet!");
    }

    // ✅ No more calling fetchAttributesData() here!
  }

  openDialog(type: string, info: any = {}): void {
    let dialogview;
    let width = "500px";
    info.parent = this;
    // if (this.isundefinedActive) {
      // Add assignedAttributeActivated flag
      info["assignedAttributeActivated"] = this.isundefinedActive;
    // }
    if (type == "createattribute") {
      dialogview = CreateFieldDialog;
      width = "650px";
    }
    if (type === "changelanguage") {
      dialogview = ChangeLanguageDialog;
    }
    if (type === "groupshuffledialog") {
      dialogview = FieldShuffleDialog;
      info["class"] = "popupshuffle";

      // Filter out selected groups before opening dialog
      info["filtered_group_list"] = this.group_custom_list.filter(
        (group) => !this.selected_group || group.id !== this.selected_group.id
      );
    }
    if (type === "changedescriptionlanguage") {
      dialogview = ChangeDescriptionLanguageDialog;
    }

    if (dialogview) {
      let dialogRef = this.dialog.open(dialogview, {
        width: width,
        panelClass: "create-master-panel",
        data: info,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (type === "groupshuffledialog") {
          this.selected_attributes_id = [];
        }
      });
    }
  }

  isChecked(id) {
    return this.selected_attributes_id.indexOf(id) > -1;
  }
  changelanguage(ele, type) {
    if (type == "description") {
      this.openDialog("changedescriptionlanguage", { val: ele });
    } else {
      this.openDialog("changelanguage", { val: ele });
    }
  }

  showMasterGroup() {
    this.mobilemasterpopup = !this.mobilemasterpopup;
    if (this.mobilemasterpopup) {
      this.mobilemasterpopupslideout = false;
    }
  }

  swipeleftactions(s, k) {
    let el = $(this._el.nativeElement);
    el.find("#les" + k)
      .find(".swipeactions")
      .show();
    el.find(".les")
      .not("#les" + k)
      .css("margin-left", "0");
    el.find("#les" + k)
      .show()
      .css("margin-left", "-80px");
  }

  showMobileSearchBar() {
    this.showmobilesearchbar = !this.showmobilesearchbar;
  }

  swiperightactions(s, k) {
    let el = $(this._el.nativeElement);
    el.find(".swipeactions").hide();
    el.find("#les" + k).css("margin-left", "0");
  }

  showSearch() {
    this.showsearchbar = !this.showsearchbar;
  }

  ngOnDestroy() {
    this._crumbs.clear();
  }
  public hovertablecellindex = -1;
  hoverTableCell(row) {
    this.hovertablecellindex = row;
  }
  hoverOutTableCell() {}

  ngAfterViewInit() {
    var mob = $(window).height() - 167;
  }
  isCheckboxChecked(element: any): boolean {
    return element.is_editable || element.is_mandatory;
  }

  isEditableDisabled(element: any): boolean {
    return element.is_mandatory;
  }
}

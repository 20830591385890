<div class="">
<div class="table-wrapper" [ngClass]="tableClass">
  <table mat-table [dataSource]="data" class="">
    <!-- Dynamic Columns -->
    <ng-container *ngFor="let column of columns" [matColumnDef]="column">
       <!-- Checkbox Column -->
       <ng-container *ngIf="column === 'checkbox'">
        <th mat-header-cell *matHeaderCellDef class="checkbox">
          <mat-checkbox
            (change)="toggleAllRows($event)"
            [checked]="isAllSelected()"
            [indeterminate]="isSomeSelected()"
            [disabled]="data.length === 0"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox
            (change)="toggleRow(element)"
            [checked]="isRowSelected(element)"
            [disabled]="data.length === 0"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container *ngIf="column !== 'checkbox'">
        <th mat-header-cell *matHeaderCellDef class="sticky-header">
          <span>{{ column | removeUnderscore }}</span>
          <ng-container *ngIf="isSorting && !['checkbox', 'log', 'action', 'status', 's_no', 'assignment', 'year'].includes(column)">
            <app-admin-table-sorting
              [sortField]="column"
              [activeField]="sortField"
              [sortOrder]="sortOrder"
              (sortEvent)="onSort($event)">
            </app-admin-table-sorting>
          </ng-container>
        </th>

      </ng-container>

      <td mat-cell *matCellDef="let element; let i = index">
        <ng-container *ngIf="column === 's_no'">
          <span> {{i + 1 + offset}} </span>
        </ng-container>
        <!-- Conditionally render buttons based on the column name -->
        <ng-container *ngIf="column === 'log'">
          <span class="button-wrapper">
            <ng-container *ngFor="let button of buttons.log">
              <button
              *ngIf="showAllLog || button.label == 'Validate Log' || button.label == 'Log'
              || button.label == 'Error Log' && element.status == -1
              || button.label == 'Accept Error' && element.status == -2
              || button.label == 'Accept Log' && element.status == 2
              || button.label == 'Accept Log' && element.status == 4
              || button.label == 'Accept Log' && element.status == 1"
              mat-button
              (click)="button.action(element)"
              [ngStyle]="button.style"
              [ngClass]="button.class"
              [disabled]="isButtonDisabled(button, element)"
            >
              {{ button.label }}
            </button>
            </ng-container>
          </span>
        </ng-container>

        <ng-container *ngIf="column === 'action'">
          <div class="button-wrapper">
        <ng-container
           *ngFor="let button of buttons?.action">
          <button
          class="action-btn"
          (click)="button?.action(element)"
          [ngClass]="button?.class ? button.class : 'action-btn-disable'"
          [ngStyle]="button?.style"
          [disabled]="button?.label !== 'Download' && element.status !== 1"
        >
         <span *ngIf="button?.label !== 'Download'">{{ button?.label }}</span>
         <span *ngIf="button?.label == 'Download' && isIcon"><svg _ngcontent-vfo-c172="" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="19" height="19" x="0" y="0" viewBox="0 0 512 512" xml:space="preserve" style="enable-background: new 0 0 512 512;"><g _ngcontent-vfo-c172=""><g _ngcontent-vfo-c172="" id="Solid"><path _ngcontent-vfo-c172="" d="m239.029 384.97a24 24 0 0 0 33.942 0l90.509-90.509a24 24 0 0 0 0-33.941 24 24 0 0 0 -33.941 0l-49.539 49.539v-262.059a24 24 0 0 0 -48 0v262.059l-49.539-49.539a24 24 0 0 0 -33.941 0 24 24 0 0 0 0 33.941z" fill="#3fc93c" data-original="#000000"></path><path _ngcontent-vfo-c172="" d="m464 232a24 24 0 0 0 -24 24v184h-368v-184a24 24 0 0 0 -48 0v192a40 40 0 0 0 40 40h384a40 40 0 0 0 40-40v-192a24 24 0 0 0 -24-24z" fill="#3fc93c" data-original="#000000"></path></g></g></svg></span>
        </button>
        </ng-container>

          </div>
        </ng-container>

        <!-- Default case: show the data -->
        <ng-container
          *ngIf="column !== 'log' && column !== 'action' && column !== 'status' && column !== 's_no'"
        >
          <span
            matTooltip="{{ formatValue(element[column]) }}"
            matTooltipDisabled="{{ !isTooltipNeeded(element[column]) }}"
          >
            {{ formatDisplayValue(element[column]) }}
          </span>
        </ng-container>
        <ng-container *ngIf="column === 'status'">
          <span
            class="status"
            [ngStyle]="{ color: statusMap[element[column]]?.color || 'black' }"
            matTooltip="{{
              statusMap[element[column]]?.label || 'Unknown Status'
            }}"
          >
            {{ statusMap[element[column]]?.label || "Unknown Status" }}
          </span>
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"></tr>
  </table>
</div>
</div>

<div class="pagination-wrapper" *ngIf="data?.length !== 0 && !isNotShowing">
  <mat-paginator
    [length]="totalCount"
    [pageSizeOptions]="pageSizeOptions"
    (page)="pageAction($event)"
    [showFirstLastButtons]="true"
  >
  </mat-paginator>
</div>
<ng-container *ngIf="data?.length === 0">
  <app-no-records-found [dataNotFoundMessage]="'No Records Found'" [isNotFoundIconShow]="true"></app-no-records-found>
</ng-container>

<!-- showFirstLastButtons -->

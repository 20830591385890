import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-admin-table-sorting',
  templateUrl: './admin-table-sorting.component.html',
  styleUrls: ['./admin-table-sorting.component.scss']
})
export class AdminTableSortingComponent {
  @Input() sortField: string = ''; // Field for this column
  @Input() activeField: string = ''; // Active column being sorted
  @Input() sortOrder: string = ''; // Current sorting order
  @Output() sortEvent = new EventEmitter<{ field: string, order: string }>();

  onSort(field: string) {
    let newOrder = 'asc'; // Default to ascending

    if (this.activeField === field) {
      // Toggle order if the same column is clicked again
      newOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    }

    // Emit sorting event
    this.sortEvent.emit({ field, order: newOrder });
  }
}

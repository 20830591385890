import { Component, Inject } from "@angular/core";
import {  FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ApiserviceService } from "../../../../apiservice.service";
import { CommonfunctionService } from "../../../../services/commonfunction.service";
import { MatErrorStateMatcher } from "../../../admin-error-state-macher";
import { NotificationService } from "../../../services/notification.service";

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_entity_attributes_get</li>
 * <li>estapi_entity_attributes_post</li>
 * <li>estapi_entity_attributes_patch</li>
 * <li>estapi_entity_attributes_attribute_id_patch</li>
 * <li>estapi_attribute_measure_unit_type_measureunits_get</li>
 * </ol>
 *
 */

@Component({
  selector: "createattribute",
  templateUrl: "admin-createattribute.component.html",
  styleUrls: ["./create-update-attribute.component.scss"],
})
export class CreateAttributeDialog {
  languages: Array<{}> = [];
  language_valueset: Array<{}> = [];
  attributeForm: FormGroup;
  measure_type = [];
  measure_unit = [];
  attributes = [];
  property_type = [];
  data_type = [];
  masters = [];
  showDefaultValue: boolean;
  showFieldLength: boolean;
  showMaster: boolean;
  showMeasure: boolean;
  defaultValueType: string = "number";
  status: boolean = true;
  showDependent: boolean = true;
  showParent: boolean = false;
  master_valid = false;

  searchText: string;
  isMeasureUnitShow: boolean;

  get attributeName() {
    return this.attributeForm.get("attribute_name");
  }
  matcher = new MatErrorStateMatcher();
  constructor(
    public dialogRef: MatDialogRef<CreateAttributeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiserviceService,
    private fnc: CommonfunctionService,
    private fb: FormBuilder,
    private notify: NotificationService
  ) {
    this.getAttributesList();
    if (this.data.sub || this.data.edit) {
      if (data.val.parent_id && this.data.edit) {
        let parent = this.fnc.getArrayValue(
          "attribute_id",
          data.val.parent_id,
          this.data.pObj.attributes_list
        );
        this.masters = this.fnc.getChildArray(
          "parent_master_id",
          parent.master_id,
          this.data.pObj.master_list
        );
      } else {
        this.masters = this.data.pObj.master_list;
      }
      if (this.data.sub && !this.data.edit) {
        // this below line was not added befoure 2021-10-14 becouse master list was not coming while adding child attribute for table type parent attibute,
        // if needed remove below line
        let parent = this.fnc.getArrayValue(
          "attribute_id",
          data.val.id,
          this.data.pObj.attributes_list
        );
        this.masters = this.fnc.getChildArray(
          "parent_master_id",
          parent.master_id,
          this.data.pObj.master_list
        );
      }
      this.showParent = true;
    } else {
      this.showParent = false;
      this.masters = this.data.pObj.master_list;
    }
    this.languages = this.api.language_list;
    //this.attributes = this.data.pObj.attributes_list;
    this.measure_type = this.data.pObj.measure_unit_type;
    this.property_type = this.api.property_type_list;
    this.data_type = this.data.pObj.data_type;
    this.attributeForm = this.fb.group({
      attribute_name: [
        { value: "", disabled: this.data.edit },
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(64),
          Validators.pattern("^[a-zA-Z0-9_]*$"),
        ]),
      ],
      property_type: [
        null,
        Validators.compose([this.conditionalValidation("property")]),
      ],
      data_type: [{ value: "", disabled: this.data.edit }, Validators.required],
      is_system_attribute: false,
      is_unique: [],
      is_frequently_search: [],
      is_language_dependent: [],
      default_value: [],
      field_length: [],
      measure_unit: [],
      measure_type: [],
      dependent_attribute: [],
      mutual_exclusive_attribute: [],
      associated_master: [],
      parent_attribute: [],
      description:['']
    });
    if (this.data.edit) {
      let detail = this.data.val;
      if (detail.measure_type) {
        this.changeMeasureUnit(detail.measure_type);
      }
      this.dataTypeChange(detail.data_type);
      let mutuallyExclusive;
      if (detail.mutually_exclusive && detail.mutually_exclusive.length > 0) {
        mutuallyExclusive = Number(detail.mutually_exclusive[0]);
      }
      this.attributeForm.patchValue({
        attribute_name: detail.name,
        property_type: detail.property_type_id,
        data_type: detail.data_type,
        measure_type: detail.measure_type,
        is_system_attribute: detail.is_system_attribute,
        is_unique: detail.is_unique,
        is_frequently_search: detail.is_frequent_search,
        is_language_dependent: detail.is_lang_dependent,
        default_value: detail.default_value,
        field_length: detail.field_length,
        measure_unit: detail.measure_unit,
        mutual_exclusive_attribute: mutuallyExclusive ? mutuallyExclusive : "",
        associated_master: detail.master_id,
        parent_attribute: detail.parent_id,
        description:detail.description,
      });
      this.status = detail.status;
    } else {
      if (this.data.pObj.selected_entity_type == "property_unit") {
        // for default selected property type
        this.attributeForm.patchValue({
          property_type: [+this.data.pObj.selected_property_type],
        });
      }
    }

    if (this.data.sub) {
      // to add sub attribute
      this.attributeForm.patchValue({ parent_attribute: this.data.val.id });
      if (this.data.val.property_id) {
        this.attributeForm.patchValue({
          property_type: this.data.val.property_id,
        });
      }
    }
  }

  conditionalValidation(field, val = null) {
    if (field == "property") {
      if (this.data.pObj.is_property_dependent) {
        // return Validators.required;
        return Validators.nullValidator;
      } else {
        return Validators.nullValidator;
      }
    }
  }

  countProperty(event) {
    if (event.length > 1) {
      this.showDependent = false;
    } else {
      this.showDependent = true;
    }
  }

  updateMasterList(val) {
    if (val) {
      let parent = this.fnc.getArrayValue(
        "attribute_id",
        val,
        this.data.pObj.attributes_list
      );
      this.masters = this.fnc.getChildArray(
        "parent_master_id",
        parent.master_id,
        this.data.pObj.master_list
      );
    } else {
      this.masters = this.data.pObj.master_list;
    }
  }

  getAttributesList() {
    let ids: any = this.data.pObj.selected_property_type;
    let url =
      this.data.pObj.selected_entity_type +
      "/attributes?sort_by=sort_order&limit=10000&offset=0";
    if (this.data.pObj.selected_entity_type == "property_unit") {
      url = url + "&property_type_ids=" + ids;
    }

    this.attributes = [];
    this.api.getEmsData(url).subscribe((res: any) => {
      if (this.data.edit) {
        let tem = [];
        res.map((e) => {
          if (e.attribute_id != this.data.val.id) {
            tem.push(e);
          }
        });
        this.attributes = tem;
      } else {
        this.attributes = res;
      }
    });
  }

  validateMaster(val) {
    if (val) {
      this.master_valid = false;
    } else {
      this.master_valid = true;
    }
  }

  saveAttribute(val: FormGroup) {
    //val.associated_master
    let value = val.value;
    if(value.measure_type != null && value.measure_unit == null && value.measure_type != "volume" && value.measure_type != "weight"){
      this.notify.notify("Measure Unit is required", "warn")
      return
    }
    if (
      ["single-select", "multi-select", "table-row", "table"].indexOf(
        value.data_type
      ) != -1
    ) {
      if (!value.associated_master) {
        this.master_valid = true;
        return;
      }
    }
    if (!this.data.edit && !isNaN(value.attribute_name.charAt(0))) {
      this.notify.notify(
        "attribute name first character should be alphabets",
        "warn"
      );
      return;
    }
    let status: number = 1;
    let property_id = [];
    let dependent_attribute = null;
    let mutual_exclusive_attribute = null;
    let is_system_attribute = null;
    let is_frequent = 0;
    let is_unique = 0;
    let is_lang = 0;
    let master_id = null;
    let measure = null;
    let parent = null;

    if (value.is_system_attribute)
      is_system_attribute = value.is_system_attribute;
    if (value.is_language_dependent) is_lang = 1;
    if (value.is_frequently_search) is_frequent = 1;
    if (value.is_unique) is_unique = 1;
    if (value.associated_master) master_id = value.associated_master;
    if (value.measure_unit) measure = value.measure_unit;
    if (value.parent_attribute) parent = value.parent_attribute;
    if (value.dependent_attribute && value.dependent_attribute != "") {
      dependent_attribute = value.dependent_attribute;
    }
    if (
      value.mutual_exclusive_attribute &&
      value.mutual_exclusive_attribute != ""
    ) {
      mutual_exclusive_attribute = value.mutual_exclusive_attribute;
    } else {
      mutual_exclusive_attribute = this.data.edit ? "000" : null;
    }
    if (value.property_type) {
      property_id = value.property_type;
    } else {
      property_id.push(this.data.pObj.selected_property_type);
    }
    if (this.data.pObj.selected_entity_type != "property_unit") {
      property_id = null;
    }
    if(value.description){
      value.description.trim().toLowerCase();
    }
    let add_attribute = {
      added_by: this.api.user_id,
      updated_by: this.api.user_id,
      attribute_name: value.attribute_name,
      data_type: value.data_type,
      default_value: value.default_value,
      dependent_attributes: dependent_attribute,
      description: value.description,
      field_length: value.field_length,
      foreign_key_attribute: null,
      foreign_key_table: null,
      is_system_attribute: is_system_attribute,
      is_frequently_searched: is_frequent,
      is_lang_dependent: is_lang,
      is_unique: is_unique,
      master_id: master_id,
      measure_unit_code: measure,
      mutually_exclusive_attributes: mutual_exclusive_attribute,
      parent_attribute_id: parent,
      property_type_ids: property_id,
      sort_order: 0,
      status: status,
    };

    if (
      this.data &&
      this.data.val &&
      this.data.val.data_type == "multi-select" &&
      value.data_type !== "multi-select" &&
      value.data_type !== undefined
    ) {
      this.notify.notify(
        "Multi-select parent can only have child of type multi-select",
        "warn"
      );
      return;
    }
    if (this.data.edit) {
      //add_attribute['property_type_id'] = property_id;
      this.api
        .patchEmsData(
          this.data.pObj.selected_entity_type +
            "/attributes/" +
            this.data.val.id,
          add_attribute
        )
        .subscribe({
          next: (data: any) => {
            this.notify.notify("Attribute updated", "success");
            this.data.pObj.search_input = "";
            this.data.pObj.searchField.patchValue("");
            this.data.pObj.searchAttribute(false);
          },
          error: (err) => {
            this.notify.notify(this.fnc.getErrorMessage(err), "error");
          },
          complete: () => this.closeDialog(),
        });
    } else {
      this.api
        .postEmsData(
          this.data.pObj.selected_entity_type + "/attributes/",
          add_attribute
        )
        .subscribe({
          next: (data: any) => {
            this.notify.notify("Attribute added", "success");
            this.data.pObj.search_input = "";
            this.data.pObj.searchField.patchValue("");
            this.data.pObj.searchAttribute(false);
          },
          error: (err) => {
            this.notify.notify(this.fnc.getErrorMessage(err), "error");
          },
          complete: () => this.closeDialog(),
        });
    }
  }

  changeMeasureUnit(val) {
    this.attributeForm.patchValue({
      measure_unit:null,
    })
    if (val == "weight" || val == "volume") {
      this.isMeasureUnitShow = false;
    } else {
      this.isMeasureUnitShow = true;
    }
    this.api.getEmsData("attribute/" + val + "/measureunits").subscribe({
      next: (data: any) => {
        this.measure_unit = data;
      },
      error: (err) => {
        console.log("Error");
      },
    });
  }
  dataTypeChange(value) {
    const selectedDataType = this.attributeForm.get('dataType')?.value;

    if (selectedDataType === 'string-input') {
      this.attributeForm.patchValue({
        is_language_dependent: true
      });
    } else {
      this.attributeForm.patchValue({
        is_language_dependent: false
      });
    }
    this.conditionalValidation("master", value); // for validation
    switch (value) {
      case "toggle":
        this.showDefaultValue = false;
        this.showMeasure = false;
        this.showMaster = false;
        this.showFieldLength = false;
        break;
      case "decimal-input":
        this.showDefaultValue = true;
        this.showMeasure = true;
        this.showMaster = false;
        this.showFieldLength = false;
        this.defaultValueType = "number";
        break;
      case "number-input":
        this.showMeasure = true;
        this.showMaster = false;
        this.showFieldLength = false;
        this.defaultValueType = "number";
        this.showDefaultValue = true;
        break;
      case "string-input":
        this.showDefaultValue = false;
        this.attributeForm.patchValue({
          default_value: "",
          is_language_dependent: true,
        });
        this.showDefaultValue = true;
        this.showFieldLength = true;
        this.showMeasure = false;
        this.showMaster = false;
        this.defaultValueType = "string";
        break;
      case "single-select":
        this.showDefaultValue = false;
        this.showMeasure = false;
        this.showMaster = true;
        this.showFieldLength = false;
        break;
      case "multi-select":
        this.showDefaultValue = false;
        this.showMeasure = false;
        this.showMaster = true;
        this.showFieldLength = false;
        break;
      case "table-row":
        this.showDefaultValue = false;
        this.showMeasure = false;
        this.showMaster = true;
        this.showFieldLength = false;
        break;
      case "table":
        this.showDefaultValue = false;
        this.showMeasure = false;
        this.showMaster = true;
        this.showFieldLength = false;
        break;
      case "date":
        this.showDefaultValue = false;
        this.showMeasure = false;
        this.showMaster = false;
        this.showFieldLength = false;
        break;
      case "year":
        this.showDefaultValue = false;
        this.showMeasure = false;
        this.showMaster = false;
        this.showFieldLength = false;
        break;
      case "month":
        this.showDefaultValue = false;
        this.showMeasure = false;
        this.showMaster = false;
        this.showFieldLength = false;
        break;
      case "day":
        this.showDefaultValue = false;
        this.showMeasure = false;
        this.showMaster = false;
        this.showFieldLength = false;
        break;
      case "videos":
        this.showDefaultValue = false;
        this.showMeasure = false;
        this.showMaster = false;
        this.showFieldLength = false;
        break;
      case "images":
        this.showDefaultValue = false;
        this.showMeasure = false;
        this.showMaster = false;
        this.showFieldLength = false;
        break;
      default:
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

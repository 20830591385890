<div class="adm-upload-container">
  <div class="outer-container">
    <div class="header">
      <h5>Observer ADM Upload</h5>
    </div>
    <div class="upload-section">
      <app-admin-file-upload
      [getResourceAccess]="getResourceAccess"
        [apiUrl]="apiUrl"
        (getAfterUpload)="AfterUploadFile()"
      ></app-admin-file-upload>
    </div>
    <button type="button" (click)="getObserverAdmData()" class="sync-btn">
      <img src="../../../assets/icon/sync-icon.svg" alt="" />
    </button>
  </div>
  <div class="table_wrapper">
    <!-- Customize Button -->
    <button
      mat-button
      [matMenuTriggerFor]="menu"
      *ngIf="columns1.length >= 10 && is_observer_adm_upload"
      class="table-setting"
    >
      <img src="../../../assets/icon/settings.svg" alt="" />
    </button>

    <!-- Dropdown Menu for Column Selection -->
    <mat-menu #menu="matMenu" class="setting-table">
      <mat-checkbox
        *ngFor="let column of columns1"
        [(ngModel)]="selectedColumnsMap[column]"
        (change)="updateSelectedColumns()"
        [disabled]="isColumnDisabled(column)"
        (click)="$event.stopPropagation()"
      >
        {{ column | removeUnderscore }}
      </mat-checkbox>
    </mat-menu>
    <div class="bbar w-100" *ngIf="loading">
      <mat-progress-bar mode="indeterminate" class="btm"></mat-progress-bar>
    </div>
    <ng-container *ngIf="selectedData">
      <app-admin-data-table
        [data]="selectedData"
        [columns]="selectedColumns"
        [buttons]="buttonConfig"
        [totalCount]="totalCount"
        (pageChangeAction)="pageChangeAction($event)"
        [statusMap]="statusMap"
        [tableClass]="'brand-table-wrapper'"
      ></app-admin-data-table>
    </ng-container>
  </div>
</div>

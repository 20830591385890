/**
 * The AdminPriceUploadComponent is responsible for managing the price upload functionality.
 * It includes methods for fetching uploaded price data, downloading templates and logs,
 * handling user actions such as accepting or rejecting uploads, and managing the UI state.
 */
import { Component } from "@angular/core";
import { AdminMenusService } from "../admin-sidebar/admin-menus.service";
import { ApiserviceService } from "../../../../src/app/apiservice.service";
import { NotificationService } from "../services/notification.service";
import { CommonfunctionService } from "../../../../src/app/services/commonfunction.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-admin-price-upload",
  templateUrl: "./admin-price-upload.component.html",
  styleUrls: ["./admin-price-upload.component.scss"],
})
export class AdminPriceUploadComponent {
  getResourceAccess;
  apiUrl: string = "transaction-upload/upload?added_by=";
  price_data_upload: { GET: boolean; PATCH: boolean; POST: boolean; DELETE: boolean };
  totalRecords: number = 0; /** Total number of records fetched from the server */
  selectedData: [] = []; /** Stores selected data for operations */
  isPriceManageView: boolean =  false; /** Determines whether the view is in 'Price Manage' mode */
  isLoading: boolean = false; /** Indicates whether a network operation is in progress */
  tableColumn: string[] = [
    /** Columns displayed in the price upload table */ "id",
    "file_name",
    "add_count",
    "update_count",
    // "process_start_date",
    // "process_end_date",
    "remark",
    "accept_start_date",
    "accept_end_date",
    "validate_end_date",
    "added_by",
    "added_date",
    "updated_by",
    "updated_date",
    "log",
    "status",
    "action",
  ];
  // Helper method for downloadLogs action
  private handleDownloadLogs = (element: any) => this.downloadLogs(element);
  /** Configuration for button actions and styles */
  buttonConfig = {
    log: [
      {
        label: "Validate Log",
        action: this.handleDownloadLogs,
        style: { hover: { "background-color": "#e0e8ff" } },
        class: "log-button",
      },
      {
        label: "Accept Log",
        action: this.handleDownloadLogs,
        style: { hover: { "background-color": "#d0ffd0" } },
        class: "log-button",
      },
      {
        label: "Error Log",
        action: this.handleDownloadLogs,
        style: { hover: { "background-color": "#d0ffd0" } },
        class: "log-button",
      },
      {
        label: "Accept Error",
        action: this.handleDownloadLogs,
        style: { hover: { "background-color": "#d0ffd0" } },
        class: "log-button",
      },
    ],
    action: [
      {
        label: "Accept",
        action: (element: any) => this.actionAcceptReject(element, "accept"),
        style: {
          border: "1px solid #008000",
          color: "green",
          "background-color": "#eaffea",
        },
      },
      {
        label: "Reject",
        action: (element: any) => this.actionAcceptReject(element, "reject"),
        style: {
          border: "1px solid #ff0000",
          color: "red",
          "background-color": "#ffeaea",
        },
      },
      {
        label: "Download",
        action: (element: any) => this.downloadUploadedFile(element),
        style: {
          border: '1px solid lightgray',
          "background-color": "#ffffff",
        },
      },
    ],
  };

  /** List of uploaded price data retrieved from the server */
  uploadedPriceDataList: [] = [];

  /** Mapping of status codes to labels and colors */
  statusMap = {
    "-1": { label: "Invalid", color: "#FF4A4A" },
    "1": { label: "Valid", color: "#81A2FF" },
    "2": { label: "Accepted", color: "#0FEB03" },
    "-2": { label: "Accept Invalid", color: "#E3DB05" },
    "0": { label: "Uploaded", color: "#AAAAAA" },
    "-3": { label: "Rejected", color: "#FF4A4A" },
    "4": { label: "Accept Progress", color: "#FF8A3E" },
    "3": { label: "Validating", color: "#ACA710" },
  };

  /** Pagination limit and offset for API calls */
  limit: number = 10;
  offset: number = 0;
  sortField: string;
  sortOrder: string;
  searchText: string = "";
  placeholderText: string = "Search File Name, Remark, Added by, Updated by or ID ";

  constructor(
    private menus: AdminMenusService,
    private apiService: ApiserviceService,
    private notify: NotificationService,
    private fnc: CommonfunctionService,
    private router: Router,
  ) {
    this.menus.setActive("transaction-upload");
  }

  /** Lifecycle hook: Called after the component initializes */
  ngOnInit(): void {
    this.getAllowResourcesApi();
  }

  getAllowResource() {
    this.price_data_upload = this.fnc.checkResourceAccess(
      "transaction_upload",
      false
    );
    if (!this.price_data_upload.GET) {
      this.router.navigateByUrl("/admin/login");
      return;
    } else {
      this.fetchUploadedPriceData();
      this.getResourceAccess = this.price_data_upload;

    }
  }

  getAllowResourcesApi() {
    this.apiService.getUserRole();
    const body = `user/resource?place_id=${this.apiService.city_id}&user_id=${this.apiService.user_id}`;
    this.apiService.getUmsData(body).subscribe({
      next: (res: any) => {
        this.apiService.allowResources = res.data;
        this.getAllowResource();
      },
      error: (err) => {
        console.error("Error fetching resources", err);
      },
    });
  }

  /**
   * Fetches uploaded price data from the server.
   */
  fetchUploadedPriceData() {
    let apiUrl = `transaction-upload/data?limit=${this.limit}&offset=${this.offset}&is_count=true`;
    if(this.sortField && this.sortOrder){
      apiUrl += `&sort_order=${this.sortOrder}&sort_by=${this.sortField}`;
    }
    if (this.searchText) {
      apiUrl += `&search=${encodeURIComponent(this.searchText)}`;
    }
    this.isLoading = true;
    this.apiService.getEpsData(apiUrl).subscribe({
      next: (response: any) => {
        this.isLoading = false;
        this.uploadedPriceDataList = response.data;
        this.totalRecords = response.totalRecord;
      },
      error: (error) => {
        console.error(error);
        this.isLoading = false;
      },
    });
  }

  /**
   * Downloads the price upload template.
   */
  downloadTemplate() {
    const apiUrl = `transaction-upload/template?user_id=${this.apiService.user_id}`;
    this.isLoading = true;
    this.apiService.getEpsData(apiUrl).subscribe({
      next: (response: any) => {
        this.isLoading = false;
        window.open(response.data, "_blank");
      },
      error: (err) => {
        this.isLoading = false;
      },
    });
  }

  /**
   * Handles pagination actions.
   * @param event Pagination event containing limit and offset
   */
  pageChangeAction(event) {
    this.limit = event.limit;
    this.offset = event.offset;
    this.fetchUploadedPriceData();
  }

  /**
   * Toggles between 'Price Manage' and 'Price Data View' modes.
   * @param type The type of view to change to
   */
  changeView(type) {
    if(!this.getResourceAccess.GET){
      this.notify.notify("Authorized to view uploaded Data");
      return
    }
    this.isPriceManageView = type !== "price-data-view";
  }

  /**
   * Refreshes the data list after uploading a file.
   */
  AfterUploadFile() {
    this.limit = 10;
    this.offset = 0;
    this.fetchUploadedPriceData();
  }

  /**
   * Downloads logs for a specific upload.
   * @param element The element containing log details
   */
  downloadLogs(element: any): void {
    let downloadFileUrl: string | null = null;
    const url = `download-log?user_id=${this.apiService.user_id}&upload_id=${element.id}`;

    if (!element.validate_log && !element.accept_log) {
      this.isLoading = true;
      this.apiService.getEpsData(url).subscribe({
        next: (res: any) => {
          this.isLoading = false;
          downloadFileUrl = res.data;
          this.notify.notify(res.message, "success");
          this.openFile(downloadFileUrl);
          this.fetchUploadedPriceData();
        },
        error: () => {
          this.isLoading = false;
          this.notify.notify("Failed to fetch logs.", "error");
        },
      });
    } else {
      downloadFileUrl =
        element.status === 1 || element.status === -1
          ? element.validate_log
          : element.accept_log;
      this.openFile(downloadFileUrl);
    }
  }

  /**
   * Opens a file in a new tab.
   * @param downloadFileUrl The URL of the file to open
   */
  private openFile(downloadFileUrl: string | null): void {
    if (downloadFileUrl) {
      window.open(downloadFileUrl, "_blank");
    } else {
      this.notify.notify("No file to download.", "warning");
    }
  }

  /**
   * Performs accept or reject action on an upload.
   * @param element The element representing the upload
   * @param type The action type ('accept' or 'reject')
   */
  actionAcceptReject(element, type) {
    const url = `transaction-upload/status?user_id=${this.apiService.user_id}&action=${type}&upload_id=${element.id}`;
    this.isLoading = true;
    const body = {};
    this.apiService.patchEpsData(url, body).subscribe({
      next: (res: any) => {
        this.isLoading = false;
        this.fetchUploadedPriceData();
        this.notify.notify(res.message, "success");
      },
      error: (err) => {
        this.isLoading = false;
      },
    });
  }

  downloadUploadedFile(element){
    this.openFile(element.file_url);
  }

  onSort(event: { field: string, order: string }) {
    this.sortField = event.field;
    this.sortOrder = event.order;
    this.fetchUploadedPriceData()
  }

  resetSorting() {
    this.sortField = '';
    this.sortOrder = '';
    this.fetchUploadedPriceData();
  }

  onSearch(text: string) {
    this.searchText = text;
    this.fetchUploadedPriceData();
  }

}

import { element } from 'protractor';
import { Component, OnInit, ViewChild } from "@angular/core";
import { CommonfunctionService } from "../../services/commonfunction.service";
import { ApiserviceService } from "../../apiservice.service";
import { NotificationService } from "../services/notification.service";
import { AdminMenusService } from "../admin-sidebar/admin-menus.service";
import { Router } from "@angular/router";
import { PageEvent } from "@angular/material/paginator";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-adm",
  templateUrl: "./adm.component.html",
  styleUrls: ["./adm.component.css"],
})
export class ADMComponent implements OnInit {
  eps_log_path =
    environment.epsUrl + "download-file?file_url=" + environment.eps_log_path;
  city_code: string;
  @ViewChild("myFileInput") myFileInput;

  loading: boolean;
  loader: boolean;
  fileName: string;

  total: number;
  defaultPageSize: number = 10;
  pageSizeOptions;
  sizeOptions: string = "10, 15, 25, 50";
  pageEvent: PageEvent;
  pageIndex = 0;

  DataADMSource = [];

  selected_assignment;
  searchAssignment: string = "";
  assignmentList = [];

  placeholderText: string = "Search Upload ID or File Name";
  search_year: string = "";
  // yearList = [];
  // selected_year = new Date().getFullYear().toString();
  fileToUpload: any;
  limit: number = 10;
  offset: number = 0;
  searchText: string = "";
  adm_access: { GET: boolean; PATCH: boolean; POST: boolean; DELETE: boolean };
  sortOrder:any = "asc";
  sortField:any;
  apiUrl: string = "unit-adm?layer_name=units&user_id=";
  selectedStatus: string = '';

  status = {
    "-1": { label: "Invalid", color: "#FF2C8C" },
    "1": { label: "Valid", color: "#0008FF" },
    "2": { label: "Accepted", color: "#008008" },
    "-2": { label: "Accept Invalid", color: "#E3DB05" },
    "0": { label: "Uploaded", color: "#AAAAAA" },
    "-3": { label: "Rejected", color: "#8B2873" },
    "4": { label: "Accept Progress", color: "#FF8A3E" },
    "3": { label: "Validating", color: "#ACA710" },
  };

  totalRecords: number = 0;
  allColumns: string[] = [
    "id", //upload_id
    "file_name",
    "assignment", //assignment_id
    "year",
    "add_count",
    "update_count",
    "delete_count",
    "existing_count",
    "added_date",
    "added_by",
    "updated_by",
    "validate_end_date",
    "accept_start_date",
    "accept_end_date",
    "log",
    "status",
    "action", //actions
  ];

  defaultColumn: string[] = [
    "id",
    "file_name",
    "assignment",
    "add_count",
    "update_count",
    "delete_count",
    "existing_count",
    "log",
    "status",
    "action",
  ];

  tableColumn: string[] = [...this.defaultColumn];
  updateColumns(updatedColumns: string[]) {
    this.tableColumn = updatedColumns;
  }

  statusOptions = [
    { value: '10', label: 'All' },
    { value: '-2', label: 'Accept Invalid' },
    { value: '4', label: 'Accept Progress' },
    { value: '2', label: 'Accepted' },
    { value: '-3', label: 'Rejected' },
    { value: '0', label: 'Uploaded' },
    { value: '1', label: 'Valid' },
    { value: '3', label: 'Validating' },
    { value: '-1', label: 'Invalid' },
  ];

  private handleDownloadLogs = (element: any, logType: string) => this.downloadLogs(element, logType);

  buttonConfig = {
    log: [
      {
        label: "Log",
        action: (element: any) => this.handleDownloadLogs(element, "log"),
        style: { hover: { "background-color": "#e0e8ff" } },
        class: "validate-log",
      },
      {
        label: "Accept Log",
        action: (element: any) => this.handleDownloadLogs(element, "accept"),
        style: { hover: { "background-color": "#d0ffd0" } },
        class: "accept-log",
      },
      {
        label: "Accept Error Log",
        action: (element: any) => this.handleDownloadLogs(element, "accept_err"),
        style: { hover: { "background-color": "#d0ffd0" } },
        class: "error-log",
      },
      {
        label: "Validate Log",
        action: (element: any) => this.handleDownloadLogs(element, "validate_err"),
        style: { hover: { "background-color": "#d0ffd0" } },
        class: "accept-error",
      },
    ],
    action: [
      {
        label: "Accept",
        action: (element: any) => this.accept_reject(element, "accept"),
        style: {},
        class: "accept-btn",
      },
      {
        label: "Reject",
        action: (element: any) => this.accept_reject(element, "reject"),
        style: {},
        class: "reject-btn",
      },
      // {
      //   label: "Download",
      //   action: (element: any) => this.downloadUploadedFile(element),
      //   style: {
      //     border: '1px solid lightgray',
      //     "background-color": "#ffffff",
      //   },
      // },
    ],
  };

  constructor(
    private notify: NotificationService,
    private router: Router,
    private api: ApiserviceService,
    private fnc: CommonfunctionService,
    private menus: AdminMenusService
  ) {
    this.menus.setActive("adm-data");
    this.city_code = this.api.city_code.toLowerCase();
    this.pageSizeOptions = this.sizeOptions.split(",").map((str) => +str);
  }
  closeMatSelectEvent(event, type) {
    if (!event) {
      if (type == "year") {
        this.search_year = "";
      } else if (type == "assignment") {
        this.searchAssignment = "";
      }
    }
  }
  ngOnInit(): void {
    // this.getYears();
    this.getAllowResourcesApi();
  }

  updateApiUrl() {
    if (this.selected_assignment) {
      this.apiUrl += `&assignment_id=${this.selected_assignment}`;
    } else {
      console.error("selected_assignment or user_id is undefined!");
    }
  }

  AfterUploadFile() {
    this.limit = 10;
    this.offset = 0;
    this.getADMStatus();
  }

  getAllowResource() {
    this.adm_access = this.fnc.checkResourceAccess("adm_data", false);
    if (!this.adm_access.GET) {
      this.router.navigateByUrl("/admin/login");
      return;
    } else {
      this.getAssignments();
    }
  }
  // getting allow resources
  getAllowResourcesApi() {
    this.api.getUserRole();
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`;
    this.api.getUmsData(body).subscribe({
      next: (res: any) => {
        this.api.allowResources = res.data;
        this.getAllowResource();
      },
      error: (err) => {},
    });
  }

  //get all assignments
  getAssignments() {
    this.assignmentList = [];
    let url = "assignments?sort_by=assignment_name&status=1";
    this.api.getEmsData(url).subscribe((data: any) => {
      //change sort order
      data = this.fnc.assignmentSortOrder(data);
      this.assignmentList = data;
    });
  }

  getAssignmentLabel(id) {
    if (id) {
      let assign = this.assignmentList.find((e) => e.assignment_id == id);
      if (assign) {
        return assign.name;
      }
      return "";
    }
    return "";
  }
  getDate(dt) {
    if (dt) {
      let d = new Date(dt);
      return this.fnc.formatDateUTC(d, true);
    }
    return "";
  }
  //create year dropdown
  // getYears() {
  //   const year = new Date().getFullYear();
  //   this.yearList.push({ val: year.toString() });
  //   for (var i = 1; i < 13; i++) {
  //     this.yearList.push({ val: (year - i).toString() });
  //   }
  // }

  pageAction($event) {
    this.pageIndex = $event.pageIndex;
    if (this.limit != $event.pageSize) {
      this.limit = $event.pageSize;
    } else {
      this.offset = this.pageIndex * $event.pageSize;
    }
    this.getADMStatus();
  }

  uploadFile() {
    if (!this.adm_access.POST) {
      this.notify.notify("User not Authorized to Upload ADM Data", "warn");
      return;
    }

    if (!this.selected_assignment) {
      this.notify.notify("Assignment is required", "warn");
      return;
    } else if (!this.fileToUpload) {
      this.notify.notify("File is required", "warn");
      return;
    }

    let formData = new FormData();
    formData.append("file", this.fileToUpload, this.fileToUpload.name);
    this.loading = true;

    this.api
      .uploadEpsData(
        `unit-adm?layer_name=units&assignment_id=${this.selected_assignment}&user_id=${this.api.user_id}`,
        formData
      )
      .subscribe({
        next: (res: any) => {
          this.loading = false;
          this.notify.notify(res.message, "success");
          if (res.status == 1) {
            this.myFileInput.nativeElement.value = "";
            this.fileToUpload = null;
            this.fileName = "";
            this.getADMStatus();
          }
        },
        error: (err) => {
          this.loading = false;
          this.myFileInput.nativeElement.value = "";
          this.fileToUpload = null;
          this.fileName = "";
        },
      });
  }
  handleFileInput($event) {
    let fileList: FileList = $event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      let pattern = /zip-*/;
      if (!file.type.match(pattern)) {
        this.notify.notify("Unsupported file type", "warn");
        return;
      }
      this.fileToUpload = file;
      this.fileName = this.fileToUpload.name;
    }
  }

  selectionChange() {
    this.resetSorting();
    this.limit = 10;
    this.offset = 0;
    this.getADMStatus();
    this.updateApiUrl()
  }

  onSearch(text: string) {
    this.searchText = text;
    this.getADMStatus(); // Call API with the search query
  }

  getADMStatus() {
    if (!this.adm_access.GET) {
      this.notify.notify("User not Authorized to Get ADM Data", "warn");
      return;
    }

    this.loader = true;
    let url = `unit-adm?layer=units&limit=${this.limit}&offset=${this.offset}`;
    if (this.selected_assignment) {
      url += `&assignment_id=${this.selected_assignment}`;
    }
    if (this.offset == 0) {
      url += `&is_count=true`;
    }
    if (this.searchText) {
      url += `&search=${encodeURIComponent(this.searchText)}`; // Append search query
    }
    if (this.sortField && this.sortOrder) {
      url += `&sort_order=${this.sortOrder}&sort_by=${this.sortField}`;
    }
    if (this.selectedStatus && this.selectedStatus !== '10') {
      url += `&status=${this.selectedStatus}`; // Append selected status to API call
    }
    this.api.getEpsData(url).subscribe({
      next: (res: any) => {
        this.loader = false;
        this.totalRecords = res.totalRecord;
        if (res.data && res.data.length > 0) {
          this.DataADMSource = res.data;
        } else {
          this.DataADMSource = [];
          this.notify.notify(res.message, "warn");
        }
        if (this.offset == 0) {
          this.total = res?.totalRecord ? res.totalRecord : 0;
        }
      },
      error: (err) => {
        this.loader = false;
        this.DataADMSource = [];
      },
    });
  }

  accept_reject(element, action) {
    let upload_id = element.id;
    if (!this.adm_access.PATCH) {
      this.notify.notify("User not Authorized to Update ADM Data", "warn");
      return;
    }

    this.loader = true;
    this.api
      .getEpsData(
        `unit-adm-accept-reject?upload_id=${upload_id}&action_type=${action}&user_id=${this.api.user_id}`
      )
      .subscribe({
        next: (res: any) => {
          this.loader = false;
          if (res && res.status == 1) {
            this.getADMStatus();
            this.notify.notify(res.message, "success");
          } else {
            this.notify.notify(res.message, "warn");
          }
        },
        error: (err) => {
          this.loader = false;
        },
      });
  }

  // downloadLogs(element: any): void {
  //   let upload_id = element.id;
  //   console.log(upload_id);
  //   if (!element || !element.id || !this.city_code) {
  //     this.notify.notify("Invalid data for log download", "warn");
  //     return;
  //   }

  //   // Construct the log file name dynamically
  //   const logFileName = `${this.city_code}_validate_${element.id}.log`;

  //   // Construct the full URL
  //   const logUrl = `${this.eps_log_path}${logFileName}`;
  // }

  downloadLogs(element: any, logType: string): void {
    if (!element || !element.id || !this.city_code) {
      this.notify.notify("Invalid data for log download", "warn");
      return;
    }
  
    // Define log file name based on type
    let logFileName = "";
    switch (logType) {
      case "log":
        logFileName = `${this.city_code}_validate_${element.id}.log`;
        console.log("logFileName",logFileName);
        console.log(element.id);
        
        
        break;
      case "accept":
        logFileName = `${this.city_code}_accept_${element.id}.log`;
        break;
      case "accept_err":
        logFileName = `${this.city_code}_accept_${element.id}_err.log`;
        break;
      case "validate_err":
        logFileName = `${this.city_code}_validate_${element.id}_err.log`;
        break;
      default:
        this.notify.notify("Invalid log type", "warn");
        return;
    }
  
    // Construct the full URL
    const logUrl = `${this.eps_log_path}${logFileName}`;
    // console.log("logUrl",logUrl);
    // Open the log file in a new tab
    window.open(logUrl, "_blank");
  }
  

  // Handle sorting event emitted from the child component
  onSort(event: { field: string; order: string }) {
    this.sortField = event.field;
    this.sortOrder = event.order;
    this.getADMStatus(); // Trigger API call
  }

  // Reset sorting function
  resetSorting() {
    this.sortField = "";
    this.sortOrder = "";
    this.getADMStatus(); // Fetch data again without sorting
  }

  pageChangeAction(event) {
    this.limit = event.limit;
    this.offset = event.offset;
    console.log("page", event.offset);
    this.getADMStatus();
  }

  // onSearchTextChange(searchValue: string) {
  //   this.searchText = searchValue;
  // }

  onStatusChange(status: string) {
    this.selectedStatus = status;
    this.getADMStatus(); // Call API when status is changed
  }

}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-admin-search-bar',
  templateUrl: './admin-search-bar.component.html',
  styleUrls: ['./admin-search-bar.component.scss']
})
export class AdminSearchBarTabComponent {
  @Input() placeholder: string = '';
  @Input() maxWidth: string = '300px';
  @Input() placeholderFontSize: string = '10px';
  searchField = new FormControl('');
  
  @Output() searchEvent = new EventEmitter<string>();

  constructor() {
    this.searchField.valueChanges
      .pipe(debounceTime(1000)) // Debounce input for 1 second
      .subscribe((term: string | null) => {
        if (term !== null) {
          term = term.trim().toLowerCase();
          this.searchEvent.emit(term);
        }
      });
  }
}

import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-admin-selection-dropdown',
  templateUrl: './admin-selection-dropdown.component.html',
  styleUrls: ['./admin-selection-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AdminSelectionDropdownComponent),
      multi: true
    }
  ]
})
export class AdminSelectionDropdownComponent implements ControlValueAccessor, OnChanges {
  @Input() label: string = 'Select';
  @Input() options: { value: any, label: string }[] = [];
  @Input() multiple: boolean = false;
  @Input() showSearchBar: boolean = false;
  @Input() searchText: string = '';
  @Output() searchTextChange = new EventEmitter<string>(); 
  @Output() selectionChangecustom = new EventEmitter<any>();

  value: any;
  filteredOptions: { value: any, label: string }[] = [];
  onChange: (value: any) => void = () => {};
  onTouched: () => void = () => {};
  isDropdownOpen = false;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.filteredOptions = [...this.options];
    this.filterOptions(); 
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.filteredOptions = [...this.options];
    }
    if(this.searchText?.length>0){
      this.filterOptions();
    }
  }

  writeValue(value: any): void {
    this.value = value;
    this.filteredOptions = [...this.options];
    this.cdr.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onSelectionChange(event: any) {
    this.value = event.value;
    this.onChange(this.value);
    this.onTouched();
    this.selectionChangecustom.emit(event.value);
    this.clearSearchText();
    this.cdr.detectChanges();
  }

  filterOptions() {
    const search = this.searchText.toLowerCase();

    this.filteredOptions = this.options.filter(option => {
      const originalValue = option.value.toString().toLowerCase();
      const formattedLabel = option.label.replace(/_/g, ' ').toLowerCase();
  
      return originalValue.includes(search) || formattedLabel.includes(search);
    });
  
    setTimeout(() => this.cdr.detectChanges(), 0);
  }

  clearSearchText() {
    this.searchText = '';
    this.filterOptions();
  }
}

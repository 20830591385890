<div class="select-wrapper">
  <mat-form-field appearance="outline" floatLabel="always">
    <mat-label>{{ label }}</mat-label>
    <mat-select [value]="value" [multiple]="multiple" (selectionChange)="onSelectionChange($event)">
      <div class="search-container">
        <input 
        *ngIf="showSearchBar"
          type="text"
          [(ngModel)]="searchText"
          (change)="filterOptions()"
          (keydown.space)="$event.stopPropagation()"
          placeholder="Search..."
          class="search-input"
          autocomplete="off"
        />
      </div>
      <mat-option class="options" *ngFor="let option of filteredOptions" [value]="option.value">
        {{ option.label | removeUnderscore }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<svg
  class="svg-cont"
  xmlns="http://www.w3.org/2000/svg"
  width="14"
  height="18"
  viewBox="0 0 100 200"
  [ngClass]="{
    'up-active': activeField === sortField && sortOrder === 'asc',
    'down-active': activeField === sortField && sortOrder === 'desc'
  }"
  (click)="onSort(sortField)"
>
  <polygon points="50,10 90,90 10,90"></polygon> <!-- Up arrow -->
  <polygon points="50,190 90,110 10,110"></polygon> <!-- Down arrow -->
</svg>

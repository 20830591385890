/**
 * Component: ManagebrandsUploadViewComponent
 *
 * Description:
 * This component is responsible for managing the uploaded brands data view.
 * It interacts with the backend API to fetch, display, and manage brands data records.
 * The component also provides functionalities to download templates and handle pagination.
 * It emits events to notify the parent component about certain actions.
 *
 * Key Features:
 * - Fetch and display uploaded brands data.
 * - Manage pagination with customizable limit and offset.
 * - Download a brands data template from the server.
 * - Emit events to inform the parent component.
 */

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { ApiserviceService } from "../../../../apiservice.service";
import { NotificationService } from "../../../services/notification.service";
import { MatDialog } from "@angular/material/dialog";
import { STATUS_MAP } from "../../models/brands-status.model";
import { ManageBrandsFilterComponent } from "../manage-brands-filter/manage-brands-filter.component";
import { BrandsRemovalConfirmationComponent } from "../brands-removal-confirmation/brands-removal-confirmation.component";

@Component({
  selector: "app-manage-brands-upload-view",
  templateUrl: "./manage-brands-upload-view.component.html",
  styleUrls: ["./manage-brands-upload-view.component.scss"],
})
export class ManageBrandsUploadViewComponent {
  @ViewChild(ManageBrandsFilterComponent)
  ManagebrandsFilterComponent!: ManageBrandsFilterComponent;
  @Input() resourceAccess: any;
  @Output() resetFilters = new EventEmitter<void>(); // Notify parent to reset
  @Output() changeToDefault = new EventEmitter<void>(); //EventEmitter to notify the parent component to reset to the default state.
  totalRecords: number = 0; // Total number of records fetched from the API.
  selectedData: any[] = []; // Holds the selected data from the table (if applicable).
  selectedRows: any[] = [];
  selectedFilterColumn = [];
  receivedFilterData;
  isLoading: boolean = false; // Loading state to indicate API calls in progress.
  is_delete_by_filter: boolean = false;
  //Table columns to display data.
  allColumns: string[] = [
    "checkbox",
    "upload_id",
    "brand_id",
    "brand_name",
    "brand_features",
    "principal_company",
    "franchise_group",
    "added_date",
    "added_by",
    "updated_date",
    "updated_by",
    // "sub_brands",
    // "number_of_stores",
    // "all_store_locations",
    // "associated_malls",
    "min_age_range",
    "max_age_range",
    "income_level",
    "popular_with",
    "min_store_size",
    "max_store_size",
    "store_type",
    "brand_performance",
    "rent_structure",
    // "annual_sales",
    "product_categories",
    "business_model",
    "sustainability_initiatives",
    "product_type",
    "product_range",
    "pricing_strategy",
    "sales_channels",
    // "min_avg_footfall",
    // "max_avg_footfall",
    "avg_footfall",
    "merchandise",
    // "competitive_brands",
    "brand_description",
    // "master_value_id",
    "leasing_person_contact",
    "leasing_person_email",
    "remark",
  ];

  defaultColumn: string[] = [
    "checkbox",
    "upload_id",
    "brand_id",
    "brand_name",
    "brand_features",
    "principal_company",
    "franchise_group",
    "added_date",
    "updated_date",
    "product_type"
  ]

  tableColumn: string[] = [...this.defaultColumn];
  updateColumns(updatedColumns: string[]) {
    this.tableColumn = updatedColumns;
  }

  uploadedbrandsDataList: any[] = []; // List of uploaded brands data fetched from the API.
  //Status mapping object to define labels and color codes for different statuses.
  statusMap = STATUS_MAP;
  limit: number = 10; //Number of records to fetch per page.
  offset: number = 0; // Offset for paginated API requests.
  isFiltersPanelOpen = false;
  badgeCount: number;
  chipsLabel: any;
  emittedIndex: number | null = null;
  selectedFilterOptions: any;
  constructor(
    private apiService: ApiserviceService, // Service to handle API requests
    private cdr: ChangeDetectorRef, // Change detector to manage view updates
    private notify: NotificationService,
    public dialog: MatDialog
  ) {}

  /**
   * Lifecycle hook: Initializes the component and fetches initial data.
   */
  ngOnInit(): void {
    this.fetchUploadedbrandsData();
  }
  get Object() {
    return Object;
  }

  /**
   * Fetches the uploaded brands data from the API.
   * Handles loading state and updates the view upon success or failure.
   */
  fetchUploadedbrandsData(): void {
    let apiUrl = `brand-upload/view-data?limit=${this.limit}&offset=${this.offset}&is_count=true`;

    // Add filter parameters if available
    if (this.receivedFilterData && Object.keys(this.receivedFilterData).length > 0) {
      const filterParam = encodeURIComponent(JSON.stringify(this.receivedFilterData));
      apiUrl += `&filter=${filterParam}`;
    }

    this.isLoading = true;
    this.apiService.getCsvData(apiUrl).subscribe({
      next: (response: any) => {
        this.selectedRows = null;
        this.isLoading = false;
        this.uploadedbrandsDataList = [...response.data];
        this.totalRecords = response.totalRecord;
        this.extractFirstObjectKeys();
        this.cdr.detectChanges();
      },
      error: (error) => {
        console.error("API Error:", error);
        this.isLoading = false;
      },
    });
  }


  // Method to extract keys of the first object
  extractFirstObjectKeys() {
    if (this.uploadedbrandsDataList && this.uploadedbrandsDataList.length > 0) {
      this.selectedFilterColumn = Object.keys(
        this.uploadedbrandsDataList[0]
      ).filter((key) => key !== "floor_number" && key !== "floor_uid");
    }
  }

  /**
   * Downloads the brands data template from the server.
   */
  downloadTemplate() {
    let apiUrl = `download-brand-data?user_id=${this.apiService.user_id}`;
    if (
      this.receivedFilterData &&
      Object.keys(this.receivedFilterData).length > 0
    ) {
      // Serialize the object into a JSON string
      const filterParam = encodeURIComponent(
        JSON.stringify(this.receivedFilterData)
      );
      apiUrl += `&filter=${filterParam}`;
    }
    this.isLoading = true;
    this.apiService.getCsvData(apiUrl).subscribe({
      next: (response: any) => {
        this.isLoading = false;
        window.open(response.data, "_blank");
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
      },
    });
  }

  /**
   * Handles pagination changes and fetches the updated data.
   * @param event - Pagination event containing the updated limit and offset.
   */
  pageChangeAction(event: { limit: number; offset: number }) {
    this.limit = event.limit;
    this.offset = event.offset;
    this.fetchUploadedbrandsData();
  }

  /**
   * Emits an event to notify the parent component to close the child component.
   * @param value - Optional value to pass to the parent component.
   */
  closeComponent(value?: any): void {
    this.changeToDefault.emit(value);
  }
  onSelectedRowsChange(selectedRows: any[]) {
    this.selectedRows = selectedRows;
  }

  /**
   * Sends a deletion request and opens a confirmation dialog.
   * @param
   */
  deleteRequest(isFilterDelete: boolean) {
    if (!this.resourceAccess.DELETE) {
      this.notify.notify(
        "Access denied: Unauthorized deletion of brands transaction data.",
        "warn"
      );
      return;
    }
    if (isFilterDelete) {
      this.is_delete_by_filter = true;
    } else {
      this.is_delete_by_filter = false;
    }
    const filter = isFilterDelete ? this.receivedFilterData : null;
    
    const brandIds = this.selectedRows?.map((item) => item.brand_id) || [];

    const data = this.is_delete_by_filter
    ? {
        user_id: this.apiService.user_id,
        filter: JSON.stringify(filter),
        is_delete_by_filter: this.is_delete_by_filter,
      }
    : {
        brand_ids: brandIds,
        user_id: this.apiService.user_id,
      };
    
    let dialogRef = this.dialog.open(BrandsRemovalConfirmationComponent, {
      width: "450px",
      panelClass: "create-master-panel",
      disableClose: true,
      data: data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selectedRows = [];
        this.fetchUploadedbrandsData();
        this.notify.notify(
          "brands transaction data has been successfully deleted",
          "success",
          5000
        );
      }
    });
  }
  // Method to handle the received data from the child
  handleReceivedData(data: any[]) {
    let transformedObject: { [key: string]: any } = {};

    // Ensure data is an array
    if (Array.isArray(data)) {
      data.forEach((item) => {
        // Check if the item has the necessary properties directly
        if (item.columnName && item.columnValue) {
          // Assign columnName as key and columnValue as value
          transformedObject[item.columnName] = item.columnValue;
        } else {
          console.error("Missing columnName or columnValue in item:", item);
        }
      });
      this.receivedFilterData = { ...this.receivedFilterData, ...transformedObject };
      this.fetchUploadedbrandsData();
    }
  }


  openFiltersPanel() {
    this.isFiltersPanelOpen = true;
  }

  closeFiltersPanel() {
    this.isFiltersPanelOpen = false;
  }
  // filterApply(event) {
  //   console.log("filterApply is calling after remove row", event);
  //   this.handleReceivedData(event);
  //   this.badgeCount = event.length;
  //   this.chipsLabel = event.map((item) => item.columnName);

  //   this.closeFiltersPanel();
  // }

  filterApply(event): void {

    // Update badge count and chips label
    this.badgeCount = event.length;
    this.chipsLabel = event.map(item => item.columnName);

    // Update received filter data and fetch filtered data
    this.receivedFilterData = {};
    event.forEach(item => {
      if (item.columnName && item.columnValue) {
        this.receivedFilterData[item.columnName] = item.columnValue;
      }
    });

    this.fetchUploadedbrandsData();
    this.closeFiltersPanel();
  }
  // resetFilter() {
  //   this.receivedFilterData = null;
  //   this.badgeCount = 0;
  //   this.chipsLabel = null;
  //   this.fetchUploadedbrandsData();
  //   this.closeFiltersPanel();
  // }
  resetFilter(): void {
    this.badgeCount = 0;
    this.chipsLabel = [];
    this.receivedFilterData = {};
    this.fetchUploadedbrandsData();
    this.closeFiltersPanel();
  }

  removeRowFromChips(index: number): void {
    if (this.ManagebrandsFilterComponent) {
      this.ManagebrandsFilterComponent.removeRowFromChips(index);
    } else {
      console.error("Child component not available.");
    }
  }

  handleFilterOptionsChanged(options: any): void {

    this.selectedFilterOptions = options;
    this.filterApply(options);
  }
  getDateRange(event: [string, string]) {

    // Add the date range as an array to receivedFilterData
    // this.receivedFilterData['date'] = event;
  }

}

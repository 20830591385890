<!--
  Component Name: brands Transaction View
  Description: This component represents the UI layout for viewing uploaded brands transactions. It includes a header section with action buttons and a content section to display data in a table format. The component also supports downloading a template and notifying the parent component for cancellation.

  Key Features:
  - Displays a list of uploaded brands transactions in a data table.
  - Allows users to download a template file.
  - Provides a cancel button to close the component.
  - Includes a loader to indicate data fetching or processing.

  Dependencies:
  - app-admin-button: A reusable button component with customizable labels and actions.
  - app-admin-data-table: A reusable data table component for displaying tabular data with pagination and status mapping.
  - ngx-loading: A third-party loader component for showing loading states.
-->

<div class="container-wrapperpadding-medium">
  <!-- Header Section -->
  <div
    class="header d-flex justify-content-between align-items-center padding-medium pl-0"
  >
    <div class="header-title-section">
      <div
        class="title-wrapper d-flex justify-content-start align-items-center pl-0"
      >
        <button
          (click)="closeComponent('brands-data-view')"
          class="back-btn pl-0"
        >
          <svg
            class="mx-1"
            xmlns="http://www.w3.org/2000/svg"
            width="13.991"
            height="24.817"
            viewBox="0 0 12.991 23.817"
          >
            <path
              id="XMLID_225_"
              d="M23.5,75.317a1.083,1.083,0,0,0-1.531,0l-10.06,10.06L1.849,75.317A1.083,1.083,0,0,0,.318,76.848L11.144,87.674a1.082,1.082,0,0,0,1.531,0L23.5,76.848A1.082,1.082,0,0,0,23.5,75.317Z"
              transform="translate(87.991 0) rotate(90)"
              fill="#ff9d5a"
              stroke="#ff9d5a"
              stroke-width="2"
            />
          </svg>
        </button>
        <!-- Page Title -->
        <h3 class="page-title">Brands Data View</h3>
      </div>
    </div>
    <div class="header-actions gap-24 align-items-center">
      <!-- filter chips -->
       <ng-container>
        <app-admin-chips
        [chipsLabel]="chipsLabel"
        (chipRemoved)="removeRowFromChips($event)"
        >
        </app-admin-chips>
       </ng-container>

      <!-- Download Template Button -->
      <ng-container *ngIf="selectedRows && selectedRows?.length > 0">
        <app-admin-button
          [label]="'Delete'"
          [class]="'delete-btn'"
          [disabled]="false"
          (action)="deleteRequest(false)"
        >
        </app-admin-button>
      </ng-container>

      <!-- Delete by filters -->
      <ng-container *ngIf="receivedFilterData && Object.keys(receivedFilterData).length > 0">
        <!-- If no records are found after applying the filter, the "Delete by Filter" button will be hidden -->
        <app-admin-button *ngIf="uploadedbrandsDataList?.length > 0 && !selectedRows?.length > 0"
          [label]="'Delete By Filters'"
          [class]="'delete-btn'"
          [disabled]="false"
          (action)="deleteRequest(true)"
        >
        </app-admin-button>
      </ng-container>

      <!-- Download Template Button -->
      <app-admin-button
        [label]="'Download'"
        [class]="'secondary-btn'"
        [disabled]="false"
        (action)="downloadTemplate()"
      >
      </app-admin-button>

      <!-- back Button -->
      <!-- <app-admin-button
        [label]="'Back'"
        [class]="'cancel-btn'"
        [disabled]="false"
        (action)="closeComponent('brands-data-view')"
      >
      </app-admin-button> -->
      <ng-container>
            <!-- Download Template Button -->
      <app-admin-button
      [label]="'Filter'"
      [class]="'cancel-btn'"
      [disabled]="false"
      [isBadgeAdded]="true"
      [badgeCount]="badgeCount"
      (action)="openFiltersPanel()"
    >
    </app-admin-button>
      </ng-container>
      <app-admin-table-setting 
        [allColumns]="allColumns" 
        [defaultColumns]="defaultColumn" 
        (columnsChanged)="updateColumns($event)">
      </app-admin-table-setting>
    </div>

  </div>
  <!-- Content Section -->
  <div class="content-section">
    <app-admin-data-table
      class="view-brand-data"
      [data]="uploadedbrandsDataList"
      [columns]="tableColumn"
      [totalCount]="totalRecords"
      [statusMap]="statusMap"
      (pageChangeAction)="pageChangeAction($event)"
      (selectedRowsChange)="onSelectedRowsChange($event)"
      [tableClass]="'brand-table-wrapper'"
    ></app-admin-data-table>
  </div>
</div>
<ng-container>
  <app-left-side-panel
    [isOpen]="isFiltersPanelOpen"
    [title]="'Set brands Filter'"
    (close)="closeFiltersPanel()"
  >
    <app-manage-brands-filter
      [columnNameList]="selectedFilterColumn"
      [emittedIndex]="emittedIndex"
      (resetFilter)="resetFilter()"
      (filterOptionsChanged)="handleFilterOptionsChanged($event)"
      (sendFilteredSectionData)="filterApply($event)"
      (dataRange)="getDateRange($event)"
    ></app-manage-brands-filter>
  </app-left-side-panel>
</ng-container>

<!-- Loader Section -->
<div *ngIf="isLoading" class="loader-div">
  <ngx-loading [show]="isLoading"></ngx-loading>
</div>

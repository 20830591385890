import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { CdkTableModule } from "@angular/cdk/table";
import { CommonModule } from "@angular/common";
import { AdminComponent } from "./admin.component";

import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AdminRoutingModule } from "./admin-routing/admin-routing.module";
import { AdminLoginComponent } from "./admin-login/admin-login.component";
import { SelectCityComponent } from "./city-select/city-select-component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NotificationService } from "./services/notification.service";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import {
  MatNativeDateModule,
  ErrorStateMatcher,
  ShowOnDirtyErrorStateMatcher,
} from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {
  MatDialogModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from "@angular/material/dialog";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTreeModule } from "@angular/material/tree";
import { FlexLayoutModule } from "@angular/flex-layout";
import { AdminMenusService } from "./admin-sidebar/admin-menus.service";
import { OverlayModule } from "@angular/cdk/overlay";
// import { ShContextMenuModule } from 'ng2-right-click-menu';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { DndModule } from "ngx-drag-drop";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AdminSidebarComponent } from "./admin-sidebar/admin-sidebar.component";
import { AdminHeaderComponent } from "./admin-header/admin-header.component";
import { AdminBreadcrumbComponent } from "./admin-breadcrumb/admin-breadcrumb.component";
import { SortablejsModule } from "@dustfoundation/ngx-sortablejs";
import { MatPaginatorModule } from "@angular/material/paginator";
import { AdminMasterComponent } from "./admin-master/admin-master.component";
import {
  UsermenuComponent,
  ProfileDialog,
} from "./admin-header/usermenu/usermenu.component";
import { NgxLoadingModule, ngxLoadingAnimationTypes } from "ngx-loading";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { AdminAttributeComponent } from "./admin-attribute/admin-attribute.component";
import { CreateAttributeDialog } from "./admin-attribute/components/create-update-attribute/admin-createattribute.component";
import { AdminBreadcrumbService } from "./admin-breadcrumb/services/admin-breadcrumb.service";
import { AppNotificationService } from "./services/app-notification.service";
import { AdminPropertyComponent } from "./admin-property/admin-property.component";
import { AdminFieldComponent } from "./admin-field/admin-field.component";
import { AuthGuard } from "./services/auth.guard";
import {
  RECAPTCHA_V3_SITE_KEY,
  RecaptchaV3Module,
  RecaptchaModule,
} from "ng-recaptcha";
import { RecaptchaFormsModule } from "ng-recaptcha";
import {
  MasterAssignmentComponent,
  LabelDialog,
  CreateMapComponent,
  ExistingPropComponent,
} from "../admin/master-assignment/master-assignment.component";
import { ShareService } from "./services/share.service";
import {
  NotificationComponent,
  NotifyDetailComponent,
} from "./notification/notification.component";

import { QaQcComponent } from "./qa-qc/qa-qc.component";
import { CsvUploadComponent } from "./csv-upload/csv-upload.component";
import { MatChipsModule } from "@angular/material/chips";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { ConfirmDialogBox } from "./confirm-dialog/confirm-dialogbox";

import { DashboardComponent } from "./dashboard/dashboard.component";

import {
  LegacyComponentComponent,
  PropertyMap,
  DataList,
} from "./csv-upload/legacy-component/legacy-component.component";
import { NewcsvLegacyComponentComponent } from "./csv-upload/newcsv-legacy-component/newcsv-legacy-component.component";
import { AdminProfileComponent } from "./admin-profile/admin-profile.component";
import { LanguageComponent } from "./language/language.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { NgxSortableModule } from "ngx-sortable";
import { AdminConfigurationComponent } from "./admin-configuration/admin-configuration.component";
import { StepToggleComponent } from "../step-toggle/step-toggle.component";
import { ParcelMapComponent } from "./qa-qc/components/parcel-map/parcel-map.component";
import { UnitMapComponent } from "./qa-qc/unit-map/unit-map.component";
import { ToolboxComponent } from "./qa-qc/unit-map/toolbox/toolbox.component";
import { UnitPasetComponent } from "./qa-qc/unit-map/unit-paste/unit-paste.component";
import { SyncPropertyComponent } from "./sync-property/sync-property.component";
import { SyncPropertyGisComponent } from "./sync-property-gis/sync-property-gis.component";
import { from } from "rxjs";
import { NgxPaginationModule } from "ngx-pagination";
import { SyncPropertyLogComponent } from "./sync-property-log/sync-property-log.component";
import { ViewReportComponent } from "./sync-property-log/view-report/view-report.component";
import { PipesModule } from "../pipes/pipes.module";
import { FilterPipe } from "../config/filterPipe";
import { SearchFilterPipe } from "../config/search-filter.pipe";
import { CopyAssignmentDialogComponent } from "./master-assignment/copy-assignment-dialog/copy-assignment-dialog.component";
import { InterceptorService } from "./interceptor.service";
import { ImageCropperModule } from "ngx-image-cropper";
import { ImageEditorComponent } from "./admin-profile/image-editor/image-editor.component";
import { ReportsDialog } from "./csv-upload/reports/report-dialog.component";
import { InfoDialog } from "./csv-upload/info-dialog/info-dialog.component";
import { PropertyOtherAssignmentComponent } from "./master-assignment/property-other-assignment/property-other-assignment.component";
import { GalleryDialog } from "./qa-qc/components/gallery/gallery-dialog.component";
import { InProgressTooltipComponent } from "./in-progress-tooltip/in-progress-tooltip.component";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { InProgressComponent } from "./in-progress/in-progress.component";
import { ADMComponent } from "./adm/adm.component";
import { HistoricalSyncDataComponent } from "./historical-sync-data/historical-sync-data.component";
import { SettingsComponent } from "./settings/settings.component";
import { AddEditComponent } from "./settings/add-edit/add-edit.component";
import { CategoryDialogComponent } from "./settings/category-dialog/category-dialog.component";
import { CreateAssignmentComponent } from "./master-assignment/create-assignment.component";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input-2";
import { SocketService } from "./socket.service";
import { ProgressBarComponent } from "./historical-sync-data/progress-bar/progress-bar.component";
import { MastersListViewComponent } from "./admin-master/masters-list-view/masters-list-view.component";
import { EditMasterValueComponent } from "./admin-master/masters-list-view/edit-master-value/edit-master-value.component";
import { ConfirmationDialog } from "./csv-upload/confirmation-box/onfirmation-dialog";
import { NewCsvUploadComponent } from "./new-csv-upload/new-csv-upload.component";
import { NewCsvConflictDataComponent } from "./new-csv-upload/new-csv-conflict-data/new-csv-conflict-data.component";
import { ShowPropertyMapComponent } from "./new-csv-upload/show-property-map/show-property-map.component";
import { ArchiveAssignmentComponent } from "./master-assignment/archive-assignment/archive-assignment.component";
import { CreatePropertyTypeDialogComponent } from "./admin-property/create-update-property-type-dialog/admin-createproperty.component";
import { CreateMasterGroupDialog } from "./admin-master/components/create-update-mastergroup/admin-creategroup.component";
import { CreateMasterValueDialog } from "./admin-master/components/create-update-master-value/admin-createvalue.component";
import { CreateMaster } from "./admin-master/components/create-update-master/admin-createmaster.component";
import { CreateSubMasterValueDialog } from "./admin-master/components/create-update-sub-master-value/admin-create-subvalue.component";
import { MasterValueDialog } from "./admin-master/components/master-value-dialog/master-value-dialog.component";
import { SelectCheckAllComponent } from "./admin-master/components/select-check-all/select-check-all.component";
import { MasterContextMenu } from "./admin-master/components/master-context-menu/master-context-menu.component";
import { SubmasterContextMenu } from "./admin-master/components/master-sub-context-menu/master-sub-context-menu.component";
import { AttributeDialog } from "./admin-attribute/components/copy-attribute-dialog/attribute-dialog.component";
import { AdminActionbuttonsComponent } from "./admin-breadcrumb/components/admin-actions/admin-action.component";
import { AdminSearchbarComponent } from "./admin-breadcrumb/components/admin-search/admin-search.component";
import { AddGroupDialog } from "./admin-configuration/components/add-group/add-group.component";
import { AddReportDialog } from "./admin-configuration/components/add-report/add-report.component";
import { DeleteComponent } from "./admin-configuration/components/delete/delete.component";
import { DeleteReportComponent } from "./admin-configuration/components/delete-report/delete-report.component";
import { EditGroupDialog } from "./admin-configuration/components/edit-group/edit-group.component";
import { GroupListDialog } from "./admin-configuration/components/group-list-dialog/groupListDialog.component";
import { SubgroupContextMenu } from "./admin-configuration/components/sub-group-context-menu/subGroupContextMenu.component";
import { GroupContextMenu } from "./admin-configuration/components/groupContextMenu/groupContextMenu.component";
import { CreateFieldDialog } from "./admin-field/components/create-update-field/admin-createfields.component";
import { ShuffleConfirmationDialog } from "./admin-field/components/Shuffle-Confirmation-Dialog/shuffle-confirmation-dialog.component";
import { FieldShuffleDialog } from "./admin-field/components/field-shuffle-dialog/field-suffle-dialog.component";
import { ChangeLanguageDialog } from "./admin-field/components/language-change/change-language-dialog.component";
import { ChangeDescriptionLanguageDialog } from "./admin-field/components/changed-scription-language/changede-scription-language.component";
import { GroupFieldsList } from "./admin-field/components/admin-group-list/admin-grouplist.component";
import { FieldSubmasterContextMenu } from "./admin-field/components/field-submaster-contextmenu/field-submaster-contextmenu.component";
import { FieldMasterContextMenu } from "./admin-field/components/field-master-contextmenu/field-master-contextmenu.component";
import { MasterMultiselect } from "./qa-qc/components/master-multiselect/master-multiselect.component";
import { ReviewMapComponent } from "./qa-qc/components/review-map/review-map.component";
import { SubmitDialog } from "./qa-qc/components/submit-dialog/submit-dialog.component";
import { BulkApproveProperty } from "./qa-qc/components/Bulk-Approve-Property/submit-bulk.component";
import { AddressListDialog } from "./qa-qc/components/Address-List-Dialog/Address-List-Dialog.component";
import { BulkRejectDialog } from "./qa-qc/components/bulk-reject-dialog/bulk-reject-dialog.component";
import { MasterDownloadComponent } from './admin-master/components/master-download/master-download.component';
import { AdminAttributeDeletionComponent } from "./admin-attribute-deletion/component/admin-attribute-deletion/admin-attribute-deletion.component";
import { AdminAttributeDeletionSearchValueComponent } from './admin-attribute-deletion/component/admin-attribute-deletion-data-table/admin-attribute-deletion-data-table';
import { AttributeDeletionRequestHistoryComponent } from './admin-attribute-deletion/component/attribute-deletion-request-history/attribute-deletion-request-history.component';
import { AttributeDeletionConfirmationComponent } from './admin-attribute-deletion/component/attribute-deletion-confirmation/attribute-deletion-confirmation.component';
import { AttributeHistoryViewComponent } from './admin-attribute-deletion/component/attribute-history-view/attribute-history-view.component';
import { AdminAttributeDeletionRequestActionComponent } from './admin-attribute-deletion/component/admin-attribute-deletion-request-action/admin-attribute-deletion-request-action.component';
import { AdminDataTableComponent } from './shared/components/admin-data-table/admin-data-table.component';
import { AdminObserverAdmUploadComponent } from './admin-observer-adm-upload/admin-observer-adm-upload.component';
import { AdminFileUploadComponent } from './shared/components/admin-file-upload/admin-file-upload.component';
import { CopyAttributeAcrossCitiesComponent } from './admin-attribute/components/copy-attribute-across-cities/copy-attribute-across-cities.component';
import { AdminVisibilityCalculationComponent } from './admin-visibility-calculation/admin-visibility-calculation.component';
import { CopyAttributeProgressComponent } from './in-progress/copy-attribute-progress/copy-attribute-progress.component';
import { RemoveUnderscorePipe } from "../pipes/removeUnderscore.pipe";
import { AdminPriceUploadComponent } from './admin-price-upload/admin-price-upload.component';
import { AdminButtonComponent } from './shared/components/admin-button/admin-button.component';
import { ManagePriceUploadViewComponent } from './admin-price-upload/components/manage-price-upload-view/manage-price-upload-view.component';
import { NoRecordsFoundComponent } from './shared/components/no-records-found/no-records-found.component';
import { PriceRemovalConfirmationComponent } from './admin-price-upload/components/price-removal-confirmation/price-removal-confirmation.component';
import { LeftSidePanelComponent } from './shared/components/left-side-panel/left-side-panel.component';
import { ManagePriceFilterComponent } from './admin-price-upload/components/manage-price-filter/manage-price-filter.component';
import { AdminSelectionDropdownComponent } from './shared/components/admin-selection-dropdown/admin-selection-dropdown.component';
import { AdminInputFieldComponent } from './shared/components/admin-input-field/admin-input-field.component';
import { AdminChipsComponent } from './shared/components/admin-chips/admin-chips.component';
import { AdminDateRangePickerComponent } from './shared/components/admin-date-range-picker/admin-date-range-picker.component';
import { AdminYearPickerComponent } from './shared/components/admin-year-picker/admin-year-picker.component';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { AdminBrandsUploadComponent } from "./admin-brands-upload/admin-brands-upload.component";
import { ManageBrandsUploadViewComponent } from "./admin-brands-upload/components/manage-brands-upload-view/manage-brands-upload-view.component";
import { BrandsRemovalConfirmationComponent } from "./admin-brands-upload/components/brands-removal-confirmation/brands-removal-confirmation.component";
import { ManageBrandsFilterComponent } from "./admin-brands-upload/components/manage-brands-filter/manage-brands-filter.component";
import { AdminSearchBarTabComponent } from './shared/components/admin-search-bar/admin-search-bar.component';
import { AdminTableSortingComponent } from './shared/components/admin-table-sorting/admin-table-sorting.component';
import { AdminTableSettingComponent } from './shared/components/admin-table-setting/admin-table-setting.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  swipeEasing: false,
};

@NgModule({
  imports: [
    CdkTableModule,
    CommonModule,
    NgxPaginationModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SortablejsModule.forRoot({ animation: 150 }),
    RecaptchaModule,
    RecaptchaV3Module,
    RecaptchaFormsModule,
    AdminRoutingModule,
    MatInputModule,
    MatTableModule,
    FormsModule,
    MatSlideToggleModule,
    // ShContextMenuModule,
    MatAutocompleteModule,
    PerfectScrollbarModule,
    MatProgressBarModule,
    NgxIntlTelInputModule,
    OverlayModule,
    DragDropModule,
    NgxSortableModule,
    MatIconModule,
    MatCheckboxModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatGridListModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTabsModule,
    MatListModule,
    MatMenuModule,
    MatTooltipModule,
    MatDialogModule,
    FlexLayoutModule,
    MatSelectModule,
    MatRadioModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    MatTreeModule,
    MatProgressSpinnerModule,
    DndModule,
    MatPaginatorModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatButtonModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBackgroundColour: "rgba(0,0,0,0.3)",
      backdropBorderRadius: "4px",
      primaryColour: "#f69230",
      secondaryColour: "#16ceed",
      tertiaryColour: "#23cb6b",
      fullScreenBackdrop: false,
    }),
    PipesModule,
    ImageCropperModule,
    SlickCarouselModule,
  ],
  declarations: [
    LabelDialog,
    AddressListDialog,
    InfoDialog,
    ReportsDialog,
    SubmitDialog,
    FilterPipe,
    SearchFilterPipe,
    BulkRejectDialog,
    BulkApproveProperty,
    AdminComponent,
    StepToggleComponent,
    AdminLoginComponent,
    AdminSidebarComponent,
    AdminHeaderComponent,
    AdminBreadcrumbComponent,
    AdminMasterComponent,
    CreateMasterGroupDialog,
    ChangeLanguageDialog,
    AdminSearchbarComponent,
    AdminActionbuttonsComponent,
    CreateMasterValueDialog,
    MasterMultiselect,
    CreateMaster,
    CreateAttributeDialog,
    CreatePropertyTypeDialogComponent,
    CreateFieldDialog,
    CreateSubMasterValueDialog,
    MasterContextMenu,
    SubmasterContextMenu,
    GroupFieldsList,
    ChangeDescriptionLanguageDialog,
    FieldMasterContextMenu,
    FieldSubmasterContextMenu,
    GroupContextMenu,
    SubgroupContextMenu,
    UsermenuComponent,
    ProfileDialog,
    AdminAttributeComponent,
    AttributeDialog,
    AdminPropertyComponent,
    AdminFieldComponent,
    FieldShuffleDialog,
    SelectCityComponent,
    MasterAssignmentComponent,
    CreateAssignmentComponent,
    CreateMapComponent,
    ExistingPropComponent,
    ShuffleConfirmationDialog,
    NotificationComponent,
    NotifyDetailComponent,
    QaQcComponent,
    GalleryDialog,
    CsvUploadComponent,
    SyncPropertyGisComponent,
    MasterValueDialog,
    LanguageComponent,
    ConfirmDialogBox,
    UnitMapComponent,
    ToolboxComponent,
    UnitPasetComponent,
    DashboardComponent,
    SelectCheckAllComponent,
    LegacyComponentComponent,
    ConfirmationDialog,
    NewcsvLegacyComponentComponent,
    PropertyMap,
    DataList,
    AdminProfileComponent,
    AdminConfigurationComponent,
    AddReportDialog,
    AddGroupDialog,
    DeleteComponent,
    EditGroupDialog,
    DeleteReportComponent,
    GroupListDialog,
    ReviewMapComponent,
    ParcelMapComponent,
    SyncPropertyComponent,
    SyncPropertyLogComponent,
    ViewReportComponent,
    CopyAssignmentDialogComponent,
    ImageEditorComponent,
    PropertyOtherAssignmentComponent,
    InProgressTooltipComponent,
    InProgressComponent,
    ADMComponent,
    HistoricalSyncDataComponent,
    SettingsComponent,
    AddEditComponent,
    CategoryDialogComponent,
    ProgressBarComponent,
    MastersListViewComponent,
    EditMasterValueComponent,
    NewCsvUploadComponent,
    NewCsvConflictDataComponent,
    ShowPropertyMapComponent,
    ArchiveAssignmentComponent,
    MasterDownloadComponent,
    AdminAttributeDeletionComponent,
    AdminAttributeDeletionSearchValueComponent,
    AttributeDeletionRequestHistoryComponent,
    AttributeDeletionConfirmationComponent,
    AttributeHistoryViewComponent,
    AdminAttributeDeletionRequestActionComponent,
    AdminDataTableComponent,
    AdminObserverAdmUploadComponent,
    AdminFileUploadComponent,
    CopyAttributeAcrossCitiesComponent,
    AdminVisibilityCalculationComponent,
    CopyAttributeProgressComponent,
    AdminPriceUploadComponent,
    AdminButtonComponent,
    ManagePriceUploadViewComponent,
    NoRecordsFoundComponent,
    PriceRemovalConfirmationComponent,
    LeftSidePanelComponent,
    ManagePriceFilterComponent,
    AdminSelectionDropdownComponent,
    AdminInputFieldComponent,
    AdminChipsComponent,
    AdminDateRangePickerComponent,
    AdminYearPickerComponent,
    AdminSearchBarTabComponent,
    AdminTableSortingComponent,
    AdminBrandsUploadComponent,
    ManageBrandsUploadViewComponent,
    BrandsRemovalConfirmationComponent,
    ManageBrandsFilterComponent,
    AdminTableSettingComponent,
  ],
  entryComponents: [
    GroupListDialog,
    DeleteReportComponent,
    EditGroupDialog,
    DeleteComponent,
    AddGroupDialog,
    AddReportDialog,
    LabelDialog,
    ProfileDialog,
    ConfirmationDialog,
    BulkApproveProperty,
    BulkRejectDialog,
    PropertyMap,
    GalleryDialog,
    AttributeDialog,
    AddressListDialog,
    SubmitDialog,
    CreateMasterValueDialog,
    CreateFieldDialog,
    CreateMasterGroupDialog,
    CreateSubMasterValueDialog,
    CreateMaster,
    MasterMultiselect,
    CreateAttributeDialog,
    CreatePropertyTypeDialogComponent,
    ChangeLanguageDialog,
    FieldShuffleDialog,
    ShuffleConfirmationDialog,
    InfoDialog,
    ReportsDialog,
    ChangeDescriptionLanguageDialog,
    ConfirmDialogBox,
    UnitPasetComponent,
    CreateAssignmentComponent,
    CreateMapComponent,
    ExistingPropComponent,
    MasterValueDialog,
    ViewReportComponent,
    CopyAssignmentDialogComponent,
    ImageEditorComponent,
    PropertyOtherAssignmentComponent,
    EditMasterValueComponent,
    NewCsvConflictDataComponent,
    ShowPropertyMapComponent,
    ArchiveAssignmentComponent,
    RemoveUnderscorePipe
  ],
  providers: [
    NotificationService,
    AdminMenusService,
    AdminBreadcrumbService,
    AuthGuard,
    ShareService,
    AppNotificationService,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: "6LcrU60UAAAAACWPSjhzScna9MxRym1WiSNzEFky",
    },
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    SocketService,
  ],
  exports: [AdminLoginComponent],
})
export class AdminModule {}

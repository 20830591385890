<!--
  The following template defines the structure and functionality for the Price Data Upload section.
  It includes a toggleable view for managing price uploads and viewing uploaded price data.
  The layout uses Angular structural directives, reusable components, and dynamic data binding.

  Structure Overview:
  1. **Container Wrapper**:
      - Displays the main content when `isPriceManageView` is `false`.
      - Includes the header with title, file upload, and actions like "View Price Data" and "Download Template".
      - Contains a data table showing the list of uploaded price data.

  2. **Manage Price View**:
      - A separate view rendered when `isPriceManageView` is `true`.
      - Allows detailed management of uploaded prices.

  3. **Loader**:
      - Displays a loading indicator when `isLoading` is `true`.

  Component and Directive Usage:
  - *ngIf*: Toggles visibility of sections based on conditions.
  - app-admin-file-upload: Custom component for file uploads.
  - app-admin-button: Reusable button component with custom actions and styling.
  - app-admin-data-table: Custom data table component for listing and managing data.
  - app-manage-price-upload-view: Component for detailed price management.

  Event Bindings:
  - (getAfterUpload)="AfterUploadFile()": Refreshes data after a file upload.
  - (action)="changeView('')": Toggles back to the default view.
  - (action)="downloadTemplate()": Triggers download of a template.
  - (pageChangeAction)="pageChangeAction($event)": Handles pagination changes.
  - (changeToDefault)="changeView($event)": Handles switching views.

  Properties:
  - isPriceManageView: Boolean flag to toggle between views.
  - isLoading: Boolean flag to display the loader.
  - uploadedPriceDataList: List of uploaded price data to populate the data table.
  - tableColumn: Array defining columns for the data table.
  - totalRecords: Total count of records for pagination.
  - statusMap: Object mapping status codes to labels and colors.
  - buttonConfig: Configuration object for dynamic buttons in the table.
-->

<div class="container-wrapper padding-medium" *ngIf="!isPriceManageView">
  <div
    class="d-flex justify-content-between align-items-center padding-top-medium padding-bottom-medium margin-bottom-large"
  >
    <div class="header-title-section">
      <div
        class="title-wrapper d-flex justify-content-start align-items-center"
      >
        <h3 class="page-title">Transaction Data Upload</h3>
        <app-admin-file-upload
        [getResourceAccess]="getResourceAccess"
          [apiUrl]="apiUrl"
          (getAfterUpload)="AfterUploadFile()"
        ></app-admin-file-upload>
        <button class="refresh-button" matButton matTooltip="Refresh" (click)="fetchUploadedPriceData()">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 22 22">
            <g id="Group_83" data-name="Group 83" transform="translate(-490.906 -11.638)">
              <circle id="Ellipse_2" data-name="Ellipse 2" cx="11" cy="11" r="11" transform="translate(490.906 11.638)" fill="#6449db"/>
              <path id="Path_7" data-name="Path 7" d="M17.4,15.516c.242-.032.521,0,.549.312l.157,1.761a.359.359,0,0,1-.714.062l-.09-1.006A6.66,6.66,0,0,1,5.708,11.127a.36.36,0,0,1,.709.124A5.941,5.941,0,0,0,16.643,16.3l-.782.07a.359.359,0,0,1-.062-.714l1.6-.143ZM8.813,8.185l-.925.083a5.941,5.941,0,0,1,10.226,5.053.36.36,0,0,0,.709.124A6.66,6.66,0,0,0,7.362,7.78l-.078-.87a.359.359,0,0,0-.714.062l.156,1.746a.358.358,0,0,0,.407.338L8.875,8.9A.359.359,0,0,0,8.813,8.185Z" transform="translate(490.136 10.345)" fill="#fff" stroke="#fff" stroke-width="0.8" fill-rule="evenodd"/>
            </g>
          </svg>

        </button>
      </div>
    </div>
    <div class="header-actions d-flex">
      <app-admin-search-bar class="srch-bar" [placeholder]="placeholderText" [maxWidth]="'400px'" (searchEvent)="onSearch($event)"></app-admin-search-bar>
      <div class="buttons-container">
      <app-admin-button
        [label]="'View Transaction Data'"
        [class]="'view-page-btn'"
        [isView]="true"
        [disabled]="false"
        (action)="changeView('')"
      >
      </app-admin-button>
      <app-admin-button
        [label]="'Download Template'"
        [class]="'template-download-btn'"
        [disabled]="false"
        [isTemplateDownload]="true"
        (action)="downloadTemplate()"
      >
      </app-admin-button>
    </div>
    </div>
  </div>
  <div class="content-section">
    <app-admin-data-table
      [data]="uploadedPriceDataList"
      [columns]="tableColumn"
      [totalCount]="totalRecords"
      [statusMap]="statusMap"
      [buttons]="buttonConfig"
      (pageChangeAction)="pageChangeAction($event)"
      [isIcon]="true"
      (sortChange)="onSort($event)"
      (resetSortingEvent)="resetSorting()"
      [isSorting]="true"
    ></app-admin-data-table>
  </div>
</div>
<ng-container *ngIf="isPriceManageView">
  <app-manage-price-upload-view (changeToDefault)="changeView($event)" [resourceAccess]="price_data_upload">
  </app-manage-price-upload-view>
</ng-container>

<!-- loader start -->
<div *ngIf="isLoading" class="loader-div">
  <ngx-loading [show]="isLoading"></ngx-loading>
</div>
<!-- loader end -->

import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-admin-table-setting',
  templateUrl: './admin-table-setting.component.html',
  styleUrls: ['./admin-table-setting.component.scss']
})
export class AdminTableSettingComponent {
  @Input() allColumns: string[] = [];
  @Input() defaultColumns: string[] = []; 
  @Output() columnsChanged = new EventEmitter<string[]>();

  isDropdownOpen: boolean = false;
  allSelected: boolean = false; // Track the state of "Select All"
  columns: { name: string; visible: boolean }[] = [];

  ngOnInit() {
    this.initializeColumns();
  }

  initializeColumns() {
    this.columns = this.allColumns.map(name => ({
      name,
      visible: this.defaultColumns.includes(name),
    }));
    this.updateColumns();
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  toggleColumnVisibility() {
    this.allSelected = this.columns.every(col => col.visible);
    this.updateColumns();
  }

  toggleSelectAll() {
    setTimeout(() => {
      this.columns.forEach(column => {
        if (!this.defaultColumns.includes(column.name)) {
          column.visible = this.allSelected;
        }
      });
      this.updateColumns();
    });
  }

  updateColumns() {
    const selectedColumns = this.columns.filter(col => col.visible).map(col => col.name);
    this.columnsChanged.emit(selectedColumns);
  }

  @HostListener('document:click', ['$event'])
  closeDropdown(event: Event) {
    const target = event.target as HTMLElement;
    if (!target.closest('.settings-container')) {
      this.isDropdownOpen = false;
    }
  }

}

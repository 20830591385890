/**
 * AdminFileUploadComponent
 *
 * This component provides functionality for administrators to upload ZIP files to a specific API endpoint.
 * It includes file validation (only ZIP files are allowed), user authorization checks, and notification handling
 * to inform the user about the success or failure of the upload process. It also supports resetting the file input
 * after the file is uploaded or an error occurs.
 *
 * Key Features:
 * - **File Validation**: Only ZIP files are allowed for upload. The component checks the file type before uploading.
 * - **Authorization Check**: Ensures the user has the necessary permissions to upload files. Displays a warning if the user does not have upload permission.
 * - **Loading Indicator**: Displays a loading spinner while the file is being uploaded.
 * - **User Notifications**: Uses a notification service to display success, warning, or error messages.
 * - **File Reset**: After a successful or failed upload, the file input is reset to allow a fresh selection.
 * - **Event Emission**: After the file is uploaded successfully, an event is emitted to notify the parent component that the upload is complete.
 *
 * Dependencies:
 * - NotificationService: For displaying notifications to the user.
 * - ApiserviceService: For interacting with the backend API (e.g., for file upload).
 * - CommonfunctionService: A utility service that may be used for common functions (currently not utilized in this component).
 *
 * @component AdminFileUploadComponent
 * @selector app-admin-file-upload
 * @templateUrl ./admin-file-upload.component.html
 * @styleUrls ['./admin-file-upload.component.scss']
 */
import { Component, EventEmitter, Output, ViewChild, ElementRef, Input, SimpleChanges } from '@angular/core';
import { NotificationService } from '../../../../../../src/app/admin/services/notification.service';
import { ApiserviceService } from '../../../../../../src/app/apiservice.service';
import { CommonfunctionService } from '../../../../../../src/app/services/commonfunction.service';

@Component({
  selector: 'app-admin-file-upload',
  templateUrl: './admin-file-upload.component.html',
  styleUrls: ['./admin-file-upload.component.scss']
})
export class AdminFileUploadComponent {

  // Input property to receive resource access permissions from the parent component
  @Input() getResourceAccess;

  // Input property to receive the API URL where the file will be uploaded
  @Input() apiUrl: string;
  @Input() uploadServerType: string = 'eps';

  // ViewChild to access the file input element in the template
  @ViewChild('myFileInput') myFileInput: ElementRef;

  // Output event emitter to notify the parent component after the file upload is completed
  @Output() getAfterUpload = new EventEmitter<any>();

  // Variable to store file upload permissions (GET, POST, PATCH, DELETE)
  file_upload_resource_access: { GET: boolean; PATCH: boolean; POST: boolean; DELETE: boolean };

  // Variable to store the file to be uploaded
  fileToUpload: File;

  // Variable to store the name of the file being uploaded
  fileName: string;

  // Loading indicator to show while the file is being uploaded
  loading: boolean = false;

  // City code, used for API requests
  city_code: string;
  isDragging: boolean = false;
  selectedFileName: string | null = null;
  selectedFile: File | null = null;

  /**
   * Constructor to initialize services and set the city code
   * @param notify Notification service for user notifications
   * @param api API service to handle API calls
   * @param fnc Common functions service (not used in the current code, but may be for future use)
   */
  constructor(
    private notify: NotificationService,
    private api: ApiserviceService,
    private fnc: CommonfunctionService,
  ) {
    this.city_code = this.api.city_code.toLowerCase();
  }

  /**
   * Angular lifecycle hook that is triggered when input properties change
   * @param changes Contains the changes to the input properties
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['getResourceAccess']) {
      // You may want to handle changes in the resource access here
    }
    if(changes['apiUrl']){
      console.log("apiUrl",  this.apiUrl);
      
    }
  }

  /**
   * Angular lifecycle hook that is triggered when the component is initialized
   */
  ngOnInit(): void {
    // Initialization logic can go here (currently not used)
    console.log("apiUrl",  this.apiUrl);
  }

  /**
   * Event handler for file input change, triggered when a file is selected
   * @param $event The file input change event
   */
  handleFileInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const validTypes = ['application/zip', 'application/x-zip-compressed', 'multipart/x-zip'];
      if (!validTypes.includes(file.type)) {
        this.notify.notify("Unsupported file type. Only ZIP files are allowed.", "warn");
        input.value = ''; 
        this.fileName = ''; 
        return;
      }
      this.fileName = file.name;
      this.fileToUpload = file;   // Assign the valid ZIP file to `fileToUpload`
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.isDragging = true;
  }

  onDragLeave() {
    this.isDragging = false;
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    this.isDragging = false;

    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
  
      const validTypes = ['application/zip', 'application/x-zip-compressed', 'multipart/x-zip'];
  
      if (!validTypes.includes(file.type)) {
        this.notify.notify("Unsupported file type. Only ZIP files are allowed.", "warn");
        return;
      }
  
      this.fileName = file.name;
      this.fileToUpload = file;   // Assign the dropped file to `fileToUpload`
    }
  }
  
  validateAndSetFile(file: File) {
    const pattern = /zip-*/;
    if (!file.type.match(pattern)) {
      this.notify.notify('Unsupported file type', 'warn');
      return;
    }
    this.fileToUpload = file;
    this.fileName = file.name;
  }

  onFileSelect(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.selectedFileName = file.name;
    }
  }

  /**
   * Method to upload the selected file
   * Ensures that the user has proper permissions and the file is valid
   */
  uploadFile() {
    // Check if the user has permission to upload
    if (!this.getResourceAccess.POST) {
      this.notify.notify("You are not authorized to upload data", "warn");
      return;
    }
  
    // Ensure that a file is selected
    if (!this.fileToUpload) {
      this.notify.notify("File is required", "warn");
      return;
    }
  
    // Ensure user_id is available
    if (!this.api.user_id) {
      this.notify.notify("User ID is missing", "error");
      return;
    }
  
    // Prepare the file data for upload
    const formData = new FormData();
    formData.append("file", this.fileToUpload, this.fileToUpload.name);
  
    // Show loading indicator while the file is being uploaded
    this.loading = true;
  
    // Ensure `user_id` is correctly included in the API URL without duplication
    let finalApiUrl = this.apiUrl;
  
    // Check if `user_id` is already present
    const userIdRegex = /([?&])user_id=([^&]*)/;
    const hasUserId = userIdRegex.test(finalApiUrl);
  
    if (hasUserId) {
      // If `user_id=` exists (empty or with a value), replace it with the correct `user_id`
      finalApiUrl = finalApiUrl.replace(userIdRegex, `$1user_id=${this.api.user_id}`);
    } else {
      // If `user_id` is missing, append it correctly
      finalApiUrl += finalApiUrl.includes("?") 
        ? `&user_id=${this.api.user_id}` 
        : `?user_id=${this.api.user_id}`;
    }
  
    // Determine the correct API method
    const uploadMethod = this.uploadServerType === 'csv'
      ? this.api.uploadCsvData.bind(this.api)
      : this.api.uploadEpsData.bind(this.api);
  
    // Call the upload method
    uploadMethod(finalApiUrl, formData).subscribe({
      next: (res: any) => this.handleUploadSuccess(res),
      error: () => this.handleUploadError(),
    });
  }
  
  

  // Handle successful upload response
  handleUploadSuccess(res: any) {
    this.loading = false;
    this.notify.notify(res.message, "success");
    this.getAfterUpload.emit();
    this.resetFileInput();
  }

  // Handle upload error
  handleUploadError() {
    this.loading = false;
    this.notify.notify("File upload failed", "error");
    this.resetFileInput();
  }


  /**
   * Method to reset the file input and clear the file name
   * Ensures that the file input is cleared and no data remains after the upload
   */
  private resetFileInput() {
    if (this.myFileInput && this.myFileInput.nativeElement) {
      this.myFileInput.nativeElement.value = "";  // Reset the file input element
    }
    this.fileToUpload = null;  // Clear the file to upload
    this.fileName = null;  // Clear the file name
  }
}

<div class="settings-container">
    <img src="../../../../../assets/icon/settings.svg" alt="Settings" (click)="toggleDropdown()" class="settings-icon" matTooltip="Customize Columns">
    <div class="dropdown" *ngIf="isDropdownOpen">
      <h4>Select Columns</h4>
      <mat-checkbox [(ngModel)]="allSelected" (change)="toggleSelectAll()">
        Select All
      </mat-checkbox>
      <mat-checkbox *ngFor="let column of columns"
        [(ngModel)]="column.visible" 
        (change)="toggleColumnVisibility()"
        [disabled]="defaultColumns.includes(column.name)">
        {{ column.name | removeUnderscore }}
      </mat-checkbox>
    </div>
</div>

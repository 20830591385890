import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { environment } from '../../../environments/environment';
import { ApiserviceService } from '../../apiservice.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { ConfirmDialogBox } from '../confirm-dialog/confirm-dialogbox';
import { NotificationService } from '../services/notification.service';
import { NewCsvConflictDataComponent } from './new-csv-conflict-data/new-csv-conflict-data.component';

@Component({
  selector: 'app-new-csv-upload',
  templateUrl: './new-csv-upload.component.html',
  styleUrls: ['./new-csv-upload.component.scss']
})
export class NewCsvUploadComponent {

  selected_File_Type='properties';
  selected_module_type='deadmin';
  selected_property_type='';

  searchType:string='';

  selected_assignment;

  searchAssignment:string='';
  assignmentList=[];
  fileName:string=null;
  fileToUpload: File;
  loading: boolean;
  loader: boolean = false;
  showChunk:boolean=false;
  @ViewChild('myFileInput') myFileInput;
  upload_report: any = [];
  placeholderText: string = "Search File Name, Assignment, Status or Uploaded ID ";
  searchText: string = "";
  fontSize: string = '12px';
  sortOrder:any = "asc";
  sortField:any;

  fileStatusForNew: any= {
    "0": "Uploaded ",
    "1": "Validated ",
    "2": "Accepted",
    "3": "Validating",
    "4": "Accepting",
    "-1": "Invalid",
    "-2": "Accept invalid",
    "-3": "Rejected",
    "5": "Conflict data",
  }
  total: number=0;
  offset: number=0;
  limit: number=10;

  sizeOptions: string = '5, 10, 15, 25, 50, 100, 200';
  pageSizeOptions: any;
  defaultPageSize: number = 10;
  pageEvent: PageEvent;
  pageIndex = 0;
  download: string='Download Template';
  new_upload_access: { GET: boolean; PATCH: boolean; POST: boolean; DELETE: boolean; };
  download_access: { GET: boolean; PATCH: boolean; POST: boolean; DELETE: boolean; };
  property_type_list: any=[];


  constructor( public api: ApiserviceService,
    private notify: NotificationService,
    private fnc : CommonfunctionService,
    private menus: AdminMenusService,
    private dialog: MatDialog){
    this.menus.setActive('new-csv-upload');
    this.pageSizeOptions = this.sizeOptions.split(',').map(str => +str);

    this.getAllowResourcesApi();

  }
// getting allow resources
getAllowResourcesApi() {
  this.api.getUserRole();
  let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
  this.api.getUmsData(body).subscribe({
    next: (res: any) => {
      this.api.allowResources = res.data;
      this.new_upload_access = this.fnc.checkResourceAccess('csv_upload', false);
      this.download_access = this.fnc.checkResourceAccess('property_data', false);
      if(this.new_upload_access.GET){
        this.getAssignments();
        this.getUnitType();
        this.getUploadStatus();

      }else{
          this.notify.notify("You are not authorized to get csv data", "warn");
          return;
      }
    },
    error: (err) => {
    },
  });
}

  reset() {
    this.fileName = '';
    this.fileToUpload = null;
    this.myFileInput.nativeElement.value = '';
  }

  getNumValue(val){
    return this.fnc.modifyInt({value: val})
  }
  /**
   * Check Partially Accepted
   * @param data 
   * @returns 
   */
  checkPartiallyAccepted(data) {
    if(data.status == -2){      
      if((data.accept_count_property > data.failed_accept_property_count) && data.failed_accept_property_count != '0'){
        return true;
      }else if((data.accept_count_floor > data.failed_accept_floor_count) && data.failed_accept_floor_count != 0){
        return true;
      }else if((data.accept_count_unit > data.faile_accept_unit_count) && data.faile_accept_unit_count != 0){
        return true;
      }else{
        return false;
      }
    }
    return false;
  }

   /**
   * Check Status
   * @param data 
   * @returns value of status
   */
  getFileStatus(element) {
    return this.fileStatusForNew[element.status];
  }
  getName(name) {
    return name[this.api.language];
  }
  pageAction(e) {
    this.pageIndex = e.pageIndex;
    if (this.limit != e.pageSize) {
      this.limit = e.pageSize;
    } else {
      this.offset = this.pageIndex * e.pageSize;
    }
    this.getUploadStatus();
  }

  handleFileInput(event) {
    let fileList: FileList = event.target.files;
    let formData: FormData = new FormData();
    if (fileList.length > 0) {
      let file: File = fileList[0];
      let pattern = /zip-*/;
      if (!file.type.match(pattern)) {
        this.notify.notify('Unsupported file type', 'warn');
        return;
      }
      this.fileToUpload = file;
      this.fileName = this.fileToUpload.name;
    }
  }

  //get all assignments
  getAssignments() {
    this.assignmentList = [];
    let url = 'assignments?sort_by=assignment_name&status=1';
    this.api.getEmsData(url).subscribe({
      next: (data: any) => {
        //change sort order
        data = this.fnc.assignmentSortOrder(data);
        this.assignmentList = data;
      },
      error: (err) => {
      },
    });
  }

getUnitType(){
  let url = "propertytypes?sort_by=property_type_name&limit=100&offset=0&status=1";
    this.api.getEmsData(url)
      .subscribe(
        (data: any) => {
          this.property_type_list = data;
        }
      );
  }



  uploadFile(val?) {
    if(!this.new_upload_access.POST){
      this.notify.notify("You are not authorized to Upload csv data", "warn");
      return;
    }
     if(!this.selected_assignment){
      this.notify.notify("Assignment is required", "warn",5000,"Dismiss")
      return;
    }else if(!this.selected_property_type || this.selected_property_type.length == 0){
      this.notify.notify("Unit type is required",  "warn",5000, "Dismiss")
      return;
    }
    if(!this.fileToUpload?.name){
      this.notify.notify("Please select file",  "warn",5000, "Dismiss")
      return;
    }


    let param = {};
    if (this.selected_assignment) {
      param['assignment_id'] = this.selected_assignment;
    }
    if (this.selected_module_type) {
      param['module_type'] = this.selected_module_type;
    }
    if (this.selected_property_type) {
      param['property_type_ids'] = this.selected_property_type.toString();
    }

    let value = JSON.stringify(param);
    value = encodeURI(value);
    let formData = new FormData();
    formData.append('file', this.fileToUpload, this.fileToUpload.name);
    this.loading = true;
      let url = `csv-upload?upload_type=${this.selected_File_Type}&user_id=${this.api.user_id}`;
      if (this.selected_assignment) url += `&assignment_id=${this.selected_assignment}`;
      if (this.selected_property_type) url += `&property_type_ids=${this.selected_property_type.toString()}`;
      this.api.postFileNode(url, formData).subscribe({
        next: (res: any) => {
          this.reset();
          this.myFileInput.nativeElement.value = '';
          if (res?.status == 200) {
            this.notify.notify(res.message, 'success');
          } else if(res?.status == 1) {
            this.notify.notify(res.message, 'success');
          }if(res?.status == 401){
              this.notify.notify(res.message, 'warn');
          } else{
            this.notify.notify(res.message, 'warn');
          }
          this.getUploadStatus();
          this.loading = false;
        }, error:(err) => {
          // this.notify.notify(err.error.message, 'error');
          this.myFileInput.nativeElement.value = '';
          this.fileName = '';
          this.loading = false;
        }
      })
      setTimeout(() => {
        this.getUploadStatus();
      }, 10000);

  }


  createCsvSample(includeData: number, status = null) {
    // console.log(includeData)
    if (!this.download_access.GET) {
      this.notify.notify("User not Authorized to download Data", "Dismiss")
      return;
    }
    // console.log("here");
    if(!this.selected_assignment){
      this.notify.notify("Assignment is required", "warn",5000,"Dismiss")
      return;
    }else if(!this.selected_property_type || this.selected_property_type.length == 0){
      this.notify.notify("Unit type is required",  "warn",5000, "Dismiss")
      return;
    }
    if (this.download == "Downloading...") return;
    // console.log("here");

    //if (!this.validate(true)) return;
    // console.log("here");

    this.download = "Downloading...";
    let url = this.selected_File_Type + "/uploads/template?";
    // console.log(url);

    if (this.selected_module_type) url += "module_type=" + this.selected_module_type;
    if (this.selected_assignment) url += "&assignment_id=" + this.selected_assignment;
    if (this.selected_property_type) url += "&property_type_ids=" + this.selected_property_type.toString();
    if (status) url += "&status=" + status; // download only under review property
    if (this.api.user_id) url += "&user_id=" + this.api.user_id;
    // console.log(includeData);
    url += "&get_data=" + includeData;
    // console.log(url);
    this.api.getCsvData(url)
      .subscribe({next:(data: any) => {
        this.download = "Download Template";
        if (data && data.url) {
          this.downloadCsvSample(data.url);

        }
        else {
          this.notify.notify(data.message, 'Dismiss', 5000)
        }

      },
        error:(err) => {
          this.download = "Download Template";
          this.notify.notify(this.fnc.getErrorMessage(err), 'Dismiss');
        }}
        );
  }

  downloadCsvSample(url: string) {
    if (!url) {
      this.notify.notify('No URL/Valid Url Found!', 'Dismiss', 5000);
      return;
    }
    else {
      let apiUrl = `${environment.csvUrl}download-template?city_id=${this.api.city_id}&file_url=${url}`;
      // console.log(apiUrl);

      window.open(apiUrl, "_blank");
    }
  }

  getUploadStatus(){

     let arr = [];
      let url = `csv-upload-master?limit=${this.limit}&offset=${this.offset}&upload_type=${this.selected_File_Type}`;
      if(this.offset == 0){
        url += `&is_count=true`;
      }else{
        url += `&is_count=false`;
      }
      if (this.searchText) {
        url += `&search=${encodeURIComponent(this.searchText)}`;
      }
      if (this.sortField && this.sortOrder) {
        url += `&sort_order=${this.sortOrder}&sort_by=${this.sortField}`;
      }
      this.loader = true;
      this.api.getNodeData(url).subscribe(
        {
          next:  (res: any) => {
          this.loader = false;
          if(res.data){
            if(this.offset == 0){
              this.total = res?.totalRecord || 0;
            }
            res.data.map((item: any) => {

              let f_count_show=false;
              let fcount = (item?.validate_count?.failed_property_count || 0 +
              item?.validate_count?.failed_floor_count || 0 +
              item?.validate_count?.failed_unit_count || 0)
              if(fcount > 0){
                f_count_show =true;
              }
              arr.push({
                f_count_show: f_count_show,
                upload_id: item.id,
                name: item.name,
                status: item.status,
                added_by: item.added_by,
                updated_by: item.updated_by,
                property_count: item?.initial_count?.property_count  || 0,
                added_date: this.fnc.formatDateUTC(new Date(item.added_date), true),
                accept_time: item?.accept_time ? item.accept_time :'-',
                validate_time: item?.validate_time? item.validate_time :'-',
                updated_date: this.fnc.formatDateUTC(new Date(item.updated_date), true),
                initial_property_count: this.fnc.modifyInt({value: item?.initial_count?.property_count  || 0}),
                initial_floor_count: this.fnc.modifyInt({value: item?.initial_count?.floor_count  || 0}),
                initial_unit_count: this.fnc.modifyInt({value: item?.initial_count?.unit_count  || 0}),
                validate_property_count : this.fnc.modifyInt({value: item?.validate_count?.property_count  || 0}),
                validate_floor_count : this.fnc.modifyInt({value: item?.validate_count?.floor_count  || 0}),
                validate_unit_count: this.fnc.modifyInt({value: item?.validate_count?.unit_count  || 0}),
                failed_validate_property_count: this.fnc.modifyInt({value: item?.validate_count?.failed_property_count || 0}),
                failed_validate_floor_count: this.fnc.modifyInt({value: item?.validate_count?.failed_floor_count || 0}),
                failed_validate_unit_count: this.fnc.modifyInt({value: item?.validate_count?.failed_unit_count || 0}),
                
                accept_count_property: item?.accept_count?.property_count  || 0,
                accept_count_floor: item?.accept_count?.floor_count  || 0,
                accept_count_unit: item?.accept_count?.unit_count  || 0,
                failed_accept_property_count: item?.accept_count?.failed_property_count || 0,
                failed_accept_floor_count: item?.accept_count?.failed_floor_count || 0,
                faile_accept_unit_count: item?.accept_count?.failed_unit_count || 0,
                
                file_link: item?.file_link,
                assignment_name: item?.assignment_label?item?.assignment_label['1']:null,
                assignment_year: item?.assignment_year ? item?.assignment_year: '',
                remarks: item?.remark ? item?.remark: null,
                validate_error_link: item?.validate_error_link,
                accept_error_link: item?.accept_error_link
              })
            })
            this.upload_report = arr;
          }else{
            this.upload_report=[];
          }

        }, error:(err) => {
          this.upload_report=[];
          this.loader = false;
          // this.notify.notify(err.error.message, "error");
        }
      });
  }


/**
 * This a function used to accept data from new csv upload method
 * @param data
 */
acceptUploadNewFile(data){
  if (!this.new_upload_access.PATCH) {
    this.notify.notify("User not Authorized to accept Data", "warn",6000, "Dismiss")
    return;
  }

  this.loader = true;
  let url = `csv-upload-accept?upload_id=${data.upload_id}&user_id=${this.api.user_id}&upload_type=${this.selected_File_Type}`;
  this.api.postNodeData(url, null).subscribe(
    {next: (data:any) => {
      if(data.status == 201){
        this.notify.notify(data.message, 'success');
      }else{
        this.notify.notify(data.message, 'warn');
      }
      this.getUploadStatus();
      this.loader = false;
    }, error: (err) => {
      this.getUploadStatus();
      this.loader = false;
      // this.notify.notify(err.error.message, "error");
    }});
}

/**
* This a function used to reject data from new csv upload method
* @param data
*/
rejectUploadNewFile(data){
  if (!this.new_upload_access.PATCH) {
    this.notify.notify("User not Authorized to reject Data", "warn", 6000, "Dismiss")
    return;
  }
  let url = `csv-upload-reject?upload_id=${data.upload_id}&user_id=${this.api.user_id}&upload_type=${this.selected_File_Type}&status=${data.status}`;
  this.api.getNodeData(url).subscribe(
    {next: (data:any) => {
      if(data.status == 201){
        this.notify.notify(data.message, 'success');
      }else{
        this.notify.notify(data.message, 'warn');
      }
      this.getUploadStatus();
      this.loader = false;
    }, error: (err) => {
      this.getUploadStatus();
      this.loader = false;
      // this.notify.notify(err.error.message, "error");
    }});
}


  hideAccept(status) {
    if ([1].indexOf(status) > -1) {
      return false;
    }
    return true;
  }
  hideReject(status) {
    if ([2, -2, -3,-1].indexOf(status) > -1) {
      return true;
    }
    return false;
  }

  downloadFile(element){
    if(!this.new_upload_access.GET){
      this.notify.notify("You are not authorized to download csv zip file", "warn");
      return;
    }
    if(element.file_link){
      let apiUrl = `${environment.csvUrl}download-template?city_id=${this.api.city_id}&upload_id=${element.upload_id}&file_url=${element.file_link}`;
      window.open(apiUrl, "_blank");
    }else{
      this.notify.notify(
        "File not found",
        "warn"
      );
    }
  }

  downloadLogs(data){
    if(data?.accept_error_link){
      window.open(`https://${data.accept_error_link}`, "_blank");
    }else if(data?.validate_error_link){
      window.open(`https://${data.validate_error_link}`, "_blank");
    }else if(data.upload_id){
      this.api.getCsvData(`download-log?upload_id=${data.upload_id}&upload_count=${data.property_count}`).subscribe({
        next:(res:any)=>{
          if(res.data){
            this.getUploadStatus();
            window.open(`https://${res.data}`, "_blank");
          }
        },
        error:()=>{

        }
      })
    }

  }


  // openConflictData
  openConflictData(upload_id){
        if(upload_id){
          this.dialog.open(NewCsvConflictDataComponent,{
            data: {id:upload_id},
            width: '90vw',
            minHeight: '400px',
            disableClose: true
          })
          .afterClosed().subscribe(res=>{
           this.getUploadStatus();
          })
        }
  }
  openConfirmation(data) {
    let _data = {
      parent_data: null,
      message: `Do you want to reject ${data.name} file?`,
    }
    const dialogRef = this.dialog.open(ConfirmDialogBox, {
      width: '350px',
      data: _data
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result == 'YES'){
        this.rejectUploadNewFile(data);
      }
    });
  }

  onSearch(text: string) {
    this.searchText = text;
    this.getUploadStatus();
  }

  onSort(event: { field: string; order: string }) {
    this.sortField = event.field;
    this.sortOrder = event.order;
    this.getUploadStatus(); // Trigger API call
  }

  resetSorting() {
    this.sortField = "";
    this.sortOrder = "";
    this.getUploadStatus(); // Fetch data again without sorting
  }

}

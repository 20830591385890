<form [formGroup]="dynamicForm" class="filter-form">
 <div class="date-range-picker">
 </div>
  <div formArrayName="rows">
    <div
      *ngFor="let row of rows.controls; let i = index"
      [formGroupName]="i"
      class="form-row"
      [ngClass]="{ 'is-last-row-activated': i === rows.length - 1 && columnNameList.length != rows.length && rows.length > 1}"
    >
      <!-- Dropdown for Column Name -->
      <!-- <select >
        <option value="" disabled>Select Column</option>
        <option *ngFor="let option of columnOptions" [value]="option">{{ option }}</option>
      </select> -->
      <div class="custom-control custom-control-selection">
        <app-admin-selection-dropdown
          formControlName="columnName"
          [label]="'Column Name'"
          [options]="getFilteredOptions(i)"
          [showSearchBar]="true"
          [searchTextChange]="onSearchTextChange($event)"
          (selectionChangecustom)="onSelectionChange(i)"
        >
        </app-admin-selection-dropdown>
      </div>
       <!-- Conditional input or date range picker based on column name -->
       <div class="custom-control" *ngIf="isDateColumn(row)">
        <app-admin-date-range-picker
          placeholderStart="Start Date"
          placeholderEnd="End Date"
          (dateRangeSelected)="onDateRangeSelected($event, i)">
        </app-admin-date-range-picker>
      </div>

      <div class="custom-control" *ngIf="!isDateColumn(row) && rows.at(i).get('columnName')?.value !== 'transaction_type' && rows.at(i).get('columnName')?.value !== 'entity_type'">
        <app-admin-input-field
          [label]="'Enter Value'"
          formControlName="columnValue"
        ></app-admin-input-field>
      </div>
          <!-- Conditionally show the Trans Type dropdown -->
          <div
          class="custom-control"
          *ngIf="rows.at(i).get('columnName')?.value === 'entity_type'"
        >
          <app-admin-selection-dropdown
            [label]="'Entity Type'"
            [options]="enityType"
            (selectionChangecustom)="onTransSelectionChange(i, $event)"
          >
          </app-admin-selection-dropdown>
        </div>
           <!-- Conditionally show the Trans Type dropdown -->
           <div
           class="custom-control"
           *ngIf="rows.at(i).get('columnName')?.value === 'transaction_type'"
         >
           <app-admin-selection-dropdown
             [label]="'Transaction Type'"
             [options]="transOptions"
             (selectionChangecustom)="onEntitySelectionChange(i, $event)"
           >
           </app-admin-selection-dropdown>
         </div>
        <!-- <div class="custom-control">
          <app-year-picker
          [startYear]="startYear"
          [currentYear]="currentYear"
          (yearSelected)="handleYearSelected($event)"
        >
        </app-year-picker>
        </div> -->
         <!-- Remove Button -->
         <button
         type="button"
         (click)="removeRow(i)"
         *ngIf="rows.length > 1"
         mat-icon-button
         class="custom-button add-row-btn"
       >
         <mat-icon>close</mat-icon>
       </button>
      <!-- Add Button -->
      <!-- <button type="button"  >+</button> -->
      <button
        mat-icon-button
        class="custom-button remove-row-btn"
        *ngIf="i === rows.length - 1 && columnNameList.length != rows.length"
        (click)="addRow();"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>

  <!-- reset Button -->
  <div class="side-panel-footer gap-24 d-flex">
    <!-- Cancel Button -->
    <app-admin-button
      [label]="'Reset All'"
      [class]="'reset-btn'"
      [disabled]="false"
      (action)="resetForm();"
    >
    </app-admin-button>
    <!-- apply filter Button -->
    <app-admin-button
      [label]="'Apply Filter'"
      [class]="'apply-btn'"
      (action)="onSubmit();"
    >
    </app-admin-button>
  </div>
</form>

<mat-toolbar *ngIf="!data.val" class="matpopup-header">
  <div>Select group to assign selected attribute</div>
</mat-toolbar>
<mat-toolbar *ngIf="data.val" class="matpopup-header">
  <div>Select parent group for {{ data.val.group_name }}</div>
</mat-toolbar>
<mat-dialog-content>
  <div fxLayout="column">
    <!--<group-list [list]="groups" [data]="data" class="popupgroup" (eventtrigger)="loadlistevent($event)"></group-list>-->
    <!-- #4 Sortable Master Groups set -->
    <div fxHide="true" fxShow.gt-sm="true" fxLayout="column">
      <div class="grouplist  {{ data && data.class }}">
        <!-- for undefined -->
        <div (click)="select(-1, -1)" class="cst rowse" *ngIf="!data.val && !is_undefined && !data.assignedAttributeActivated" fxLayout="column"
          fxLayoutAlign="start left">
          <div class="group-b" [ngClass]="{ active: isActive(-1) }">
            <span class="w-100 title">Unassigned Attributes</span>
          </div>
        </div>
        <!-- undefined end -->
        <ng-container *ngFor="let item of groups; let k = index">
          <div class="cst rowse" fxLayout="column" fxLayoutAlign="start left" *ngIf="item.status == 1">
            <div class="group-b" [ngClass]="{ active: isActive(k) }" (click)="select(k, item.id)">
              {{ item.group_name }}
              <mat-icon class="add-b" [ngClass]="{ open: isActive(k) }" (click)="select(k)"
                *ngIf="item.childrens.length && !data.val" svgIcon="down-arrow-icon"></mat-icon>
            </div>

            <!-- #5 Submenu Master group -->
            <div *ngIf="!data.val" class="cs" fxLayout="row" fxLayoutAlign="start start">
              <mat-nav-list class="masterslist w-100" [sortablejs]="item.childrens"
                [sortablejsOptions]="submastergroupoptions" [ngClass]="{ active: isActive(k) }"
                *ngIf="item.childrens && item.childrens.length">
                <ng-container *ngFor="let childitem of item.childrens; let ks = index">
                  <mat-list-item (click)="selectSub(ks, childitem.id)" class="item"
                    [ngClass]="{ active: isActiveSub(ks) }">
                    <div class="txt">{{ childitem.group_name }}</div>
                  </mat-list-item>
                </ng-container>
              </mat-nav-list>
            </div>
            <!-- #5 End of submaster group -->
          </div>
        </ng-container>
      </div>
    </div>
    <!-- End of #4 -->

    <!--#1 For Mobile -->
    <div fxShow="true" fxShow.gt-sm="false" fxLayout="row" fxLayoutAlign="space-between left">
      <div class="m-grouplist">
        <mat-nav-list class="mobilemastergroups" [sortablejs]="groups" [sortablejsOptions]="mobilegroupoptions">
          <ng-container *ngFor="let item of group_list; let k = index">
            <mat-list-item class="mobilerowslist item"><mat-icon fxFlex="10%" class="draghandle"
                svgIcon="move-icon"></mat-icon>
              <div (click)="loadGroup()" fxFlex="80%" class="txt">
                {{ item.group_name }}
              </div>
              <mat-icon fxFlex="10%" (click)="
                  showsmallpopmobile($event, 'm-tooltip-nearmaster', k, item)
                ">more_vert</mat-icon>
            </mat-list-item>
          </ng-container>
        </mat-nav-list>
      </div>
      <field-master-contextmenu [item]="currentitem" *ngIf="!data" class="m-tooltip-nearmaster"
        [ngClass]="{ showtool: popwindow >= 0 }" (editclick)="editgroup($event)"></field-master-contextmenu>
      <div *ngIf="!data.val" class="m-subgrouplist" (swipeleft)="swiped()">
        <mat-nav-list class="mobilesubmastergroups">
          <ng-container *ngFor="let item of submasterlist; let k = index">
            <mat-list-item class="item"><mat-icon class="draghandle" fxFlex="10%" svgIcon="move-icon"></mat-icon>
              <div fxFlex="76%" (click)="loadGroup()" class="txt">
                {{ item.group_name }}
              </div>
              <mat-icon fxFlex="10%" *ngIf="!data" (click)="
                  showsmallsubpop($event, 'm-tooltip-subnearmaster', k, true)
                ">more_vert</mat-icon>
            </mat-list-item>
          </ng-container>
        </mat-nav-list>
      </div>
      <field-submaster-contextmenu *ngIf="!data" class="m-tooltip-subnearmaster"
        [ngClass]="{ showsubtool: popsubwindow >= 0 }"></field-submaster-contextmenu>
    </div>
    <!--#1 End For Mobile -->
  </div>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" class="dialog-actions">
  <button *ngIf="!data.val" [disabled]="!selected_group_id" type="button" (click)="shuffleFields()" mat-raised-button
    class="blue-button">
    Assign
  </button>
  <button *ngIf="data.val" [disabled]="!selected_group_id" type="button" (click)="shuffleGroups()" mat-raised-button
    class="blue-button">
    Shuffle
  </button>
  <button type="button" mat-raised-button (click)="closeDialog()">
    Cancel
  </button>
</mat-dialog-actions>
<!-- loader start -->
<div *ngIf="loader" class="loader-div">
  <ngx-loading [show]="loader" [config]="{ backdropBackgroundColour: 'rgba(255,255,255,0.3)' }"></ngx-loading>
</div>
<!-- loader end -->
